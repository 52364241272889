export default {
    cartHasProductFromAnotherBusinessArea(productsInCart, currentProduct) {
        var isDirty = false;
        for (var i = 0; i < productsInCart.length; i++) {
            if (productsInCart[i].IsClimateScreen !== currentProduct.IsClimateScreen) {
                isDirty = true;
                break;
            }
        }
        return isDirty;
    }
}