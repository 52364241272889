import * as types from './mutation-types.translation';
import moment from 'moment';

export default {
    [types.SAVE_CULTURE_AND_TRANSLATIONS](state, { payload }) {
        var translations = JSON.parse(payload.translations);

        state.culture = payload.culture;
        state.translations = translations;
        state.decimalseparator = payload.decimalseparator;
        state.timestamp = moment().format(moment.HTML5_FMT.DATETIME_LOCAL);
    }
};