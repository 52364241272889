import * as types from './mutation-types.productmodal';
import * as store from './index.productmodal';

export default {
    [types.SELECT_TAB](state, {tabindex}){
        state.open = true;
        state.selectedtab = tabindex;
    },
    [types.CLOSE_MODAL](state){
        Object.assign(state, store.getDefaultProductModalState());
    }
};