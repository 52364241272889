<template>
    <div>
        <div class="row normal-variant">
            <div class="col-md-12">
                <vue-numeric :min="0" v-model="quantity"></vue-numeric> {{t['Products - Pieces Abbreviation']}}
            </div>
            <div class="col-md-12">
                <button class="btn btn-default btn c1-bg c1-text" @click="updateCart()">{{t['Products - Update cart']}}</button>
            </div>
        </div>
        <span v-if="showItemIsUpdatedMessage">{{t['Products - Item Is Updated']}}</span>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VueNumeric from '../plugins/vue-numeric';

export default {
    name: 'SampleOrHangerEdit',
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data: function() {
        return {
            quantity: 0,
            showItemIsUpdatedMessage: false
        };
    },
    components: {
        VueNumeric
    },
    computed: {
        ...mapGetters({
            t: 'translations',
            cart: 'cart'
        }),
        productInCart(){
            return this.cart.products.find(p => p.Id === this.item.Id)
        }
    },
    methods: {
        ...mapActions([
            'changeQuantityAndLength'
        ]),
        updateCart(){
            if (this.productInCart) {
                var payload = {
                    'product' : this.productInCart,
                    'orderlength' : null,
                    'quantity' : this.quantity
                };     
                this.changeQuantityAndLength(payload);

                this.showItemIsUpdatedMessage = true;
                var self = this;
                setTimeout(function() {
                    self.showItemIsUpdatedMessage = false;
                }, 1500);
            }
        }
    }, 
    mounted() {
        if (this.productInCart) {
            this.quantity = this.productInCart.Quantity;
        }
    }
};
</script>

<style lang="scss">
    
</style>
