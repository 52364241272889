<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'OrderConfirmation',
    data: function() {
        return {
        };
    },
    computed: {
        ...mapGetters({
            t: 'translations'
        })
    },
    methods: {
        ...mapActions([
            'resetCartStateKeepCustomer'
        ])
    },
    created() {
        this.resetCartStateKeepCustomer();
    }
};
</script>
<template>
</template>
