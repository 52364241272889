(function($) {

    var $siteModal = $("#siteModal"),
        $siteDialog = $(".modal-dialog", $siteModal),
        $siteContent = $(".modal-content", $siteModal);

    $.modal = {
        open: function(opts) {
            if ($.modal.isOpen()) $.modal.close();

            opts = $.extend({
                url: "",
                data: {}
            }, opts);

            if (opts.url === "") return;

            $siteDialog.removeAttr("class").addClass("modal-dialog");
            $siteContent.removeAttr("class").addClass("modal-content").empty();           
            $siteModal.modal("show").spin();

            $.ajax({
                url: opts.url,
                method: "GET",
                dataType: "html",
                data: opts.data,
                cache: false,
                success: function (data) {
                    const content = $(data).find(".modal-content").html();
                    $siteContent.html(content);
                    $siteModal.spin(false);
                },
                error: function(error) {
                    $siteContent.spin(false).append("<div />").addClass("alert alert-danger").text(error);
                }
            });
        },
        isOpen: function() {
            return $siteModal.css("display") === "block";
        },
        close: function() {
            $siteModal.modal("hide");
        }
    };

    $siteModal.on("hidden.bs.modal", function() {
        $siteContent.empty();
    });

    $(document).delegate("*[data-modal]", "click", function(e) {
        e.preventDefault();
        $.modal.open({ url: $(this).data("modal") });
    });

})(jQuery);