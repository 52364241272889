<script>
import { mapGetters, mapActions } from 'vuex';
import apiService from "../services/apiservice";

export default {
    name: 'OrderHistory',
    data: function() {
        return {
            cmpOrderRows: [],
            totalPages: 0,
            prevDisabled: true,
            nextDisabled: true,
            currentPage: 1,
            pageRange: [],
            startPage: 0,
            endPage: 0,
            pageSize: 10,
            openRows: []
        };
    },
    computed: {
        ...mapGetters({
            t: 'translations'
        }),
        orderRows: {
            get: function() {
                return this.cmpOrderRows;
            },
            set: function(orderRows) {
                this.cmpOrderRows = orderRows;
            }
        }
    },
    methods: {
        showSpinner() {
            $(".loading-spinner").show();
        },
        hideSpinner() {
            $(".loading-spinner").hide();
        },
        orderedQty(idx) {
            let total = 0;
            if (this.orderRows) {
                for (var i = 0; i < this.orderRows[idx].orderInformationLines.length; i++) {
                    total += this.orderRows[idx].orderInformationLines[i].orderedQty;
                }
            }
            return total;
        },
        showPage() {
            const self = this;
            self.showSpinner();
            apiService.getOrderHistory(self.currentPage, 2).then(function(response) {
                self.hideSpinner();
                if (response && response.data) {
                    self.orderRows = response.data.Items;
                    self.pageRange = response.data.PageRange;
                    self.totalPages = response.data.TotalPages;
                    self.startPage = response.data.StartPage;
                    self.endPage = response.data.EndPage;
                    self.pageSize = response.data.PageSize;
                    self.prevDisabled = response.data.PageRange.indexOf(1) !== -1;
                    self.nextDisabled = response.data.PageRange.indexOf(response.data.TotalPages) !== -1;
                }
            });
        },
        changePage(e) {
            const self = this;
            e.preventDefault();
            self.openRows.length = 0;
            self.currentPage = Number(e.target.dataset.index);
            self.showPage();
        },
        clickPrev(e) {
            const self = this;
            e.preventDefault();

            if (self.startPage <= self.pageSize) {
                self.startPage = 1;
                self.endPage = self.pageSize;
            } else {
                self.startPage -= self.pageSize;
                self.endPage -= self.pageSize;
            }
            
            self.changePagination();
            self.prevDisabled = self.pageRange.indexOf(1) !== -1;
            self.nextDisabled = false;
        },
        clickNext(e) {
            const self = this;
            e.preventDefault();

            var comparePages = (self.totalPages - self.pageSize);

            if (self.startPage >= comparePages) {
                self.startPage = comparePages;
                self.endPage = self.totalPages;
            } else {
                self.startPage += self.pageSize;
                self.endPage += self.pageSize;
            }

            self.changePagination();
            self.nextDisabled = self.pageRange.indexOf(self.totalPages) !== -1;
            self.prevDisabled = false;
        },
        changePagination() {
            const self = this;
            self.pageRange.length = 0;
            self.openRows.length = 0;
            for (let i = self.startPage; i <= self.endPage; i++) {
                if (i > self.totalPages) {
                    break;
                }
                self.pageRange.push(i);
            }
        },
        toggleRow(idx, salesOrderNo) {
            var self = this, currentIdx = self.openRows.indexOf(idx);
            if (currentIdx > -1) {
                self.openRows.splice(currentIdx, 1);
                
            } else {
                self.openRows.push(idx);

                if (self.orderRows) {
                    apiService.getOrderConfirmationPdf(salesOrderNo).then(function (result) {
                        if (result && result.data) {
                            self.orderRows[idx].orderConfirmation = result.data.find(function (doc) {
                                return doc.docType === 0;
                            });

                            self.orderRows[idx].invoices = result.data.filter(function (doc) {
                                return doc.docType === 1;
                            })
                        }
                    });
                }
            }
        },
        showOrderInfo(idx) {
            return this.openRows.indexOf(idx) > -1;
        },
        downloadPdfFile(salesOrderNo, orderDocument) {
            window.location = "/Umbraco/Api/Order/GetOrderConfirmationPdfContent?orderNumber=" + salesOrderNo + "&docId=" + orderDocument.docId + "&docName=" + orderDocument.docName;
        },
        orderStatus(statusText) {
            return this.t['OrderHistory - Order Status ' + statusText];
        }
    },
    mounted() {
        this.showPage();
    }
};
</script>
<template>
    <div>
        <div v-if="orderRows.length > 0">
            <div class="row header">
                <div class="col-xs-1 col-md-1"></div>
                <div class="col-xs-2 col-md-2">{{t['OrderHistory - Ordernumber']}}</div>
                <div class="col-xs-2 col-md-2">{{t['OrderHistory - Customer Ordernumber']}}</div>
                <div class="col-xs-2 col-md-2">{{t['OrderHistory - Order date']}}</div>
                <div class="col-xs-2 col-md-2">{{t['OrderHistory - Order status']}}</div>
                <div class="col-xs-2 col-md-2">{{t['OrderHistory - Order amount']}}</div>
            </div>
            <div v-for="(order, idx) in orderRows" v-bind:key="order.salesOrderNo" class="striped order-row" v-bind:class="{ open: showOrderInfo(idx) }">
                <div class="row" v-on:click="toggleRow(idx, order.salesOrderNo)">
                    <div class="col-xs-1 col-md-1">
                        <label class="checkbox-container">
                            <input type="checkbox" class="checkbox"> <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="col-xs-2 col-md-2">{{order.salesOrderNo}}</div>
                    <div class="col-xs-2 col-md-2">{{order.customersOrderNo}}</div>
                    <div class="col-xs-2 col-md-2">{{order.orderDate}}</div>
                    <div class="col-xs-2 col-md-2">{{orderStatus(order.highestSOStatus)}}</div>
                    <div class="col-xs-2 col-md-2">{{order.netOrderValueFormatted}}</div>
                    <i class="fas fa-angle-down"></i>
                </div>
                <div class="clearfix" v-if="order.orderConfirmationDocExist || order.invoiceDocExist"></div>
                <div class="row header order-row__info order-row__info--heading" v-if="order.orderConfirmationDocExist || order.invoiceDocExist">
                    <div class="col-xs-1 col-md-1"></div>
                    <div class="col-xs-1 col-md-1"></div>
                    <div class="col-xs-5 col-md-5">
                        <div v-if="order.orderConfirmationDocExist">
                            {{t['OrderHistory - Order Confirmation Label']}}

                            <button v-if="order.orderConfirmation" v-on:click="downloadPdfFile(order.salesOrderNo, order.orderConfirmation)" class="order-document">
                                <span class="fa fa-file-pdf-o"></span>
                            </button>
                        </div>
                    </div>
                    <div class="col-xs-5 col-md-5">
                        <div v-if="order.invoiceDocExist">
                            {{t['OrderHistory - Order Invoice Label']}}

                            <button v-for="invoice in order.invoices" v-bind:key="invoice.docName" v-on:click="downloadPdfFile(order.salesOrderNo, invoice)" class="order-document">
                                <span class="fa fa-file-pdf-o"></span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="row header order-row__info order-row__info--heading">
                    <div class="col-xs-1 col-md-1"></div>
                    <div class="col-xs-1 col-md-1"></div>
                    <div class="col-xs-2 col-md-2">{{t['Common - Product']}}</div>
                    <div class="col-xs-2 col-md-2">{{t['OrderHistory - Order status']}}</div>
                    <div class="col-xs-2 col-md-2">{{t['OrderHistory - Order Dispatchdate']}}</div>
                    <div class="col-xs-2 col-md-2">{{t['Common - Quantity']}}</div>
                    <div class="col-xs-2 col-md-2">{{t['OrderHistory - Order supplier']}}</div>
                </div>
                <div class="row striped order-row__info order-row__info--data" v-for="(row, idx) in order.orderInformationLines" v-bind:key="row.salesOrderLine">
                    <div class="col-xs-1 col-md-1 col"></div>
                    <div class="col-xs-1 col-md-1 col">
                        <label class="checkbox-container">
                            <input type="checkbox" class="checkbox"> <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="col-xs-2 col-md-2 col">{{row.itemName}}</div>
                    <div class="col-xs-2 col-md-2 col">{{orderStatus(row.highestSOStatus)}}</div>
                    <div class="col-xs-2 col-md-2 col">{{row.confDispatchDate}}</div>
                    <div class="col-xs-2 col-md-2 col">{{row.orderedQty}} {{row.stormUoM}}</div>
                    <div class="col-xs-2 col-md-2 col">
                      <span v-for="info in row.orderInformationPackages" :key="info.packageNo">
                        {{info.carrier}} 
                        <span v-if="info.carrierIsUps">
                          <a :href="`https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${info.freightTrackingNo}`" target="_blank">{{info.freightTrackingNo}}</a>
                        </span>
                        <span v-if="info.carrierIsDhl">
                          <a :href="`https://www.dhl.com/se-en/home/tracking.html?tracking-id=${info.freightTrackingNo}&submit=1`" target="_blank">{{info.freightTrackingNo}}</a>
                        </span>
                      </span>
                    </div>
                    <div class="clearfix col"></div>
                </div>
            </div>
        </div>

        <nav v-if="pageRange.length > 1">
            <ul class="pagination">
                <li v-bind:class="{ disabled: prevDisabled }">
                    <span v-if="prevDisabled">
                        <span aria-hidden="true">&laquo;</span>
                    </span>
                    <a href="#" @click="clickPrev" v-if="!prevDisabled"><span aria-hidden="true">&laquo;</span></a>
                </li>
                <li v-for="(page, idx) in pageRange" v-bind:key="idx" v-bind:class="{ active: page === currentPage }">
                    <a href="#" @click="changePage" :data-index="page">{{page}}</a>
                </li>
                <li v-bind:class="{ disabled: nextDisabled }">
                    <span v-if="nextDisabled">
                        <span aria-hidden="true">&raquo;</span>
                    </span>
                    <a href="#" @click="clickNext" v-if="!nextDisabled"><span aria-hidden="true">&raquo;</span></a>
                </li>
            </ul>
        </nav>
    </div>
</template>
