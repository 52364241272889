import * as types from './mutation-types.translation';
import apiService from '../services/apiservice'
import moment from 'moment';

export default {
    saveCultureAndTranslations({commit, state}, payload) {

        var reloadTranslations = false;
        if (state.culture !== payload.culture) {
            reloadTranslations = true;
        } else if (state.timestamp.length > 0) {
            var timestamp = moment(state.timestamp);
            if (timestamp.isValid()) {
                var diff = moment().diff(timestamp, 'minutes');
                if (diff > 5) {
                    reloadTranslations = true;
                }
            } else {
                reloadTranslations = true;
            }
        } else {
            reloadTranslations = true;
        }

        if (reloadTranslations) {
            commit(types.SAVE_CULTURE_AND_TRANSLATIONS,
                {
                    payload
                });
        }
    }    
}