<template>
	<div class="cart">
		<a class="cart-size" v-bind:disabled="checkoutIsClicked" v-on:click="goToCheckout()"> {{ cartSize }} <img v-if="icon" v-bind:src="icon" class="shopping-cart" v-on:click="goToCheckout()" /></a>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'MiniCart',
    props: ['checkout', 'icon'],
    data: function() {
        return {
            checkoutIsClicked: false
        };
    },
    computed:  {
        ...mapGetters({
            cart: 'cart',
            culture: 'culture'
        }),
        cartSize() {
            if (this.cart.products) {
            return this.cart.products.reduce((cartSize, p) => {
                return cartSize + p.Quantity
                }, 0)
            }

            return 0;
        }
    },
    methods: {
        goToCheckout(){
            this.checkoutIsClicked = true;
            $(".loading-spinner").show();
            window.location.href = this.checkout;
        }
    }
};
</script>
<style lang="scss">
.cart {
    position: absolute;
    right: 15px;
    top: 47px;
    text-align: right;
    height: 40px;
    margin: 0;
    z-index: 1000;
    cursor: pointer;

    .fa-shopping-cart {
        padding: 1em 0em 1em 0;
        font-size: 16px;
    }

    .cart-size {
        padding: 0.7em 0.7em 0.7em 0.2em;
        color: #777772;
        font-size: 12px;

        img {
        width: 15px;
        float: left;
        margin-top: 5px;

        @media (min-width:0) and (max-width:991px) {
            fill: transparent;
        }
       
         }
        &:hover {
            color: #777772;
    }
    }
}

</style>
