import Actions from './actions.productmodal';
import Mutations from './mutations.productmodal';

export const getDefaultProductModalState = () => {
    return {
        open: false,
        selectedtab: -1
    }
} 

// initial state
const state = getDefaultProductModalState();

// getters
const getters = {
    open: state => state.open,
    selectedtab: state => state.selectedtab
};

export default {
    state: state,
    getters: getters,
    actions: Actions,
    mutations: Mutations
};