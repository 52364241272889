import apiService from '../services/apiservice'
import * as types from './mutation-types.cart';

export default {
    async saveChosenDeliveryAddress({ commit, rootState, state }, index) {
        var address = state.cart.customer.deliveryAddresses[index];
        var culture = rootState.translationModule.culture;
        try {
            const response = await apiService.updateDeliveryAddress(state.cart.basketId, culture, address);
            if (response !== false) {
                commit(types.SAVE_CHOSEN_DELIVERY_ADDRESS, {
                    index
                });
            }
            return response;
        }
        catch (error) {
            reject(error);
        }
    },
    addNewDeliveryAddress({ commit }, address) {
        commit(types.ADD_NEW_DELIVERY_ADDRESS, {
            address
        });
    },
    addNewDeliveryPhone({ commit }, phone) {
        commit(types.ADD_NEW_DELIVERY_PHONE, {
            phone
        });
    },
    resetCartState({ commit }) {
        commit(types.RESET_STATE)
    },
    resetCartStateKeepCustomer({ commit }) {
        commit(types.RESET_STATE_KEEP_CUSTOMER)
    },
    resetCustomerInCart({ commit }) {
        commit(types.RESET_CUSTOMER_IN_CART)
    },
    async addCustomerToCart({ commit, rootState, state }, payload) {
        var customer = payload.customer;
        var customerAddedFromSalesForce = payload.customerAddedFromSalesForce;
        commit(types.CURRENT_CUSTOMER,
            {
                customer
            });

        if (customer.deliveryAddresses.length > 0) {
            var currentDeliveryAddressIndex = 0;
            for (var i = 0; i < customer.deliveryAddresses.length; i++) {
                if (customer.deliveryAddresses[i].defaultAddress) {
                    currentDeliveryAddressIndex = i;
                    break;
                }
            }

            commit(types.CURRENT_DELIVERY_ADDRESS_INDEX, {
                currentDeliveryAddressIndex
            });
        }        

        if (customerAddedFromSalesForce) {
            commit(types.CUSTOMER_ADDED_FROM_SALESFORCE);
        }

        commit(types.CUSTOMER_EXISTS_IN_CART);

        if (state.cart.basketId > 0) {
            var culture = rootState.translationModule.culture;
            try {
                const response = await apiService.addCustomerToBasket(state.cart.basketId, customer, culture);
                if (response !== false) {
                    commit(types.CUSTOMER_EXISTS_IN_BASKET);
                }
                return response;
            }
            catch (error) {
                reject(error);
            }
        } else {
            return true;
        }
    },
    markCustomerExistsInBasket({commit}){
        commit(types.CUSTOMER_EXISTS_IN_BASKET);
    },
    emptyCart({ commit }, cart) {
        commit(types.EMPTY_CART,
            {
                cart
            });
    },
    async saveToCart({ commit, dispatch, rootState, state }, product) {

        if (state.cart.basketId === 0) {
            var culture = rootState.translationModule.culture;
            var customer = state.cart.customer;
            try {
                const response = await apiService.createBasket(customer, state.customerAddedFromSalesForce, culture);
                if (response !== false) {
                    var basket = response.data;
                    var basketId = basket.Id;
                    commit(types.ADD_BASKETID_TO_CART,
                        {
                            basketId
                        });

                    if (Object.entries(customer).length > 0 && customer.constructor === Object) {
                        commit(types.CUSTOMER_EXISTS_IN_BASKET);
                    }

                    commit(types.ADD_TO_CART,
                        {
                            product
                        });
            
                    dispatch('handleQueue', product);
                }
                return response;
            }
            catch (error) {
                reject(error);
            }
        } else {
            commit(types.ADD_TO_CART,
                {
                    product
                });
    
            dispatch('handleQueue', product);

            return true;
        }
    },
    async removeFromCart({ commit, rootState, state }, product) {
        var partNo = product.partNo;
        try {
            const response = await apiService.removeFromBasket(state.cart, partNo, rootState.translationModule.culture)
            if (response !== false) {
                commit(types.REMOVE_PRODUCT_FROM_CART_BY_PART_NO,
                    {
                        partNo
                    });
            }
            return response;
        }
        catch (error) {
            reject(error);
        }
    },
    addMarkingToCart({commit}, marking){
        commit(types.ADD_MARKING_TO_CART, {
            marking
        });
    },
    addCopackingToCart({commit}, copacking){
        commit(types.ADD_COPACKING_TO_CART, {
            copacking
        });
    },
    addPartialdeliveryacceptedToCart({commit}, partialdeliveryaccepted){
        commit(types.ADD_PARTIAL_DELIVERY_ACCEPTED_TO_CART, {
            partialdeliveryaccepted
        });
    },
    addRequestedDeliveryDateToCart({commit}, requesteddeliverydate) {
        commit(types.ADD_REQUESTED_DELIVERY_DATE_TO_CART, {
            requesteddeliverydate
        });
    },
    addCustomerOrderNumberToCart({commit}, customerordernumber) {
        commit(types.ADD_CUSTOMER_ORDERNUMBER_TO_CART, {
            customerordernumber
        });
    },
    changeQuantity({ commit, dispatch }, payload) {
        commit(types.CHANGE_QUANTITY,
            {
                payload
            });
            
            dispatch('handleQueue', payload.product);
    },
    changeQuantityAndLength({ commit, state, dispatch }, payload) {
        commit(types.CHANGE_QUANTITY_AND_LENGTH,
            {
                payload
            });
            
            var product = state.cart.products.find(p => p.Id == payload.product.Id);

            dispatch('handleQueue', product);

            if (product.Quantity == 0) {
                commit(types.REMOVE_PRODUCT_FROM_CART,
                    {
                        product
                    });
            }
    },  
    changeQuantityAndCutmeasures ({ commit, state, dispatch }, payload) {
        commit(types.CHANGE_QUANTITY_AND_CUTMEASURE,
            {
                payload
            });

            var product = state.cart.products.find(p => p.Id == payload.product.Id);

            dispatch('handleQueue', product);
            
            if (product.Quantity == 0) {
                commit(types.REMOVE_PRODUCT_FROM_CART,
                    {
                        product
                    });
            }
    },
    handleQueue({ commit, state, rootState }, product){
        var productInQueue = state.cartQueue.find(p => p.Id == product.Id);
        if (productInQueue){

            commit(types.UPDATE_CART_QUEUE,
                {
                    product
                });
        }
        else {
            commit(types.ADD_TO_CART_QUEUE,
                {
                    product
                });

            setTimeout(function () {
                var productInQueue = state.cartQueue.find(p => p.Id == product.Id);
                if (productInQueue) {
                    var culture = rootState.translationModule.culture;
                    apiService.createOrUpdateBasketItem(state.cart.basketId, productInQueue, state.customerAddedFromSalesForce, culture).then(function(){
                        commit(types.REMOVE_FROM_CART_QUEUE,
                            {
                                productInQueue
                            });
                    });
                }
            }, 2000);
        }
    }
}