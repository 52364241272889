<template>
  <div class="btn-group-xs">
    <button type="button" :disabled="buttonDisabled" class="btn minus" v-bind:class="{ zero: productIsNotInCart, disabled: buttonDisabled }" v-on:click="quantityChange(product, 'decrement')"><i class="close fa fa-minus"></i></button>
    <span v-if="showQuantity">{{quantity}}</span>
    <button type="button" :disabled="buttonDisabled" class="btn plus" v-bind:class="{ disabled: buttonDisabled }" v-on:click="quantityChange(product, 'increment')"><i class="close fa fa-plus"></i></button>
  </div>
</template>
<script>
	import { mapActions, mapGetters } from 'vuex';
	import cartvalidator from '../../cartvalidator';

	export default {
		name: 'AddToCart',
		props: {
			product: {
				type: Object,
				required: true
			},        
			purchaselimit: {
				type: Number
			},
			showQuantity: {
				type: Boolean,
				default: true
			},
			customerRequired: {
				type: Boolean,
				default: true
			}
		},
		data: function () {
			return {
				quantityButtonClicked: false
			}
		},
		computed: {
			...mapGetters({
				cart: 'cart',
				customerExistsInCart: 'customerExistsInCart'
			}),
			quantity() {
				const foundProduct = this.getProductFromCart(this.product);
				if (foundProduct) {
					return foundProduct.Quantity;
				} else {
					return "";
				}
			},
			productIsNotInCart() {
				return this.getProductFromCart(this.product) === undefined;
      		},
			customerNotInCart() {
				return !this.customerExistsInCart;
			},
			buttonDisabled() {
				if (this.customerRequired && this.customerNotInCart) {
					return true;
				} else if (this.quantityButtonClicked && !this.basketExists && this.productIsNotInCart) {
					return true;
				}
				return false;
			},
			basketExists(){
				return this.cart.basketId > 0;
			}
		},
		methods: {
			...mapActions([
				'saveToCart',
				'changeQuantity'
			]),
			getProductFromCart: function (product) {
				return this.cart.products.find(p => p.Id === product.Id && p.Name === product.Name);
			},
			quantityChange: function (product, direction) {
				var self = this;
				self.quantityButtonClicked = true;

				if (this.customerRequired && !this.customerExistsInCart) {
					$("#CustomerNeedsToBeAdded").animate({ display: "toggle" }, 1500);
					return;
				}

				if (cartvalidator.cartHasProductFromAnotherBusinessArea(this.cart.products, product)) {
					return;
				}

				const foundProduct = this.getProductFromCart(product);
				if (foundProduct) {
					this.changeQuantity({ 'product': foundProduct, 'direction': direction, 'purchaselimit': this.purchaselimit });
					self.quantityButtonClicked = false;
					if (direction === 'increment') {
						$("#AddedItemToCart").animate({ display: "toggle" }, 1500);
					} else {
						$("#RemovedItemFromCart").animate({ display: "toggle" }, 1500);
					}

				} else if (direction === 'increment') {
					product.Quantity = 1;
					this.saveToCart(product).then(function(response){
						$("#AddedItemToCart").animate({ display: "toggle" }, 1500);
						self.quantityButtonClicked = false;
					});
				}

				this.updateOrderOnCheckout();
			},
			updateOrderOnCheckout() {
				this.$emit('updateorder');
			}
		}
	};
</script>
<style lang="scss">
	.btn-group-xs {
		i.fa {
			margin: 0;
		}
	}
</style>