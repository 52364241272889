<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'ProductItem',
    props: {
        item: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        productbaseurl: {
            type: String,
            required: true
        },
        csproduct: {
            type: Boolean
        }
    },
    data: function() {
        return {
        };
    },
    computed: {
        ...mapGetters({
            t: 'translations'
        }),
        mainImageUrl() {
            if (this.item.Image) {
                return this.item.Image;
            } else {
                return "/src/images/placeholder.png";
            }
        },
        productUrl() {
            return this.productbaseurl + "/" + this.item.UniqueName;
        },
        productClass() {
            return this.item.Name.substring(0, this.item.Name.indexOf(" ")).toUpperCase();            
        }        
    }
};
</script>
<template>
        <div class="col-xs-6 col-sm-6 col-lg-4 margin-bottom productItem" v-bind:class="{ csproduct: this.csproduct } ">
            <a :href="productUrl">
                <img :src="mainImageUrl" :alt="item.Name" /><br />
                <div class="productItem-info" v-bind:class="productClass">
                    <span class="productItem-name">
                        {{item.Name}} <span v-if="item.Discontinued">{{t['Products - Product Discontinued']}}</span>
                    </span>
                    <span><label class="checkbox-container"><input type="checkbox" class="checkbox" /><span class="checkmark"></span></label>
                          <span class="productItem-compare">Compare</span>  <i class="fa fa-question-circle"></i></span>
                   
                </div>
            </a>
        </div>

</template>
