<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'ProductCallToAction',
    props: {
        usertype: {
            type: String,
            required: true
        }
    },
    data: function() {
        return {};
    },
    computed: {
        ...mapGetters({
            t: 'translations',
            cart: 'cart'
        }),
        accessToOrder() {
            return this.usertype == "payingcustomer" || (this.usertype == "salesrep" && this.cart.customer.companyName);
        },
        verifiedCustomer() {
            var customerNumberExists = this.cart.customer.customerNo && this.cart.customer.customerNo !== "";
            return customerNumberExists && this.cart.customer.webCustomer;
        }
    },
    methods: {
        ...mapActions([
            'selectTab'
        ]),
		openTab(e) {
            var index = Number(e.target.dataset.index);            
            this.selectTab(index);
            document.body.classList.add("modal-open");
		}
    }
};
</script>
<template>
    <div>
        <div class="productPage__button-container">
            <button type="button" v-bind:disabled="!accessToOrder" v-bind:class="{ disabled: !accessToOrder }" class="btn btn-default c1-bg c1-text" @click="openTab" :data-index="0">{{t['Products - Order']}}</button>
            <button type="button" class="btn btn-default c2-bg c2-text c2-borders" @click="openTab" :data-index="1">{{t['Products - Select sample']}}</button>
        </div>
        <small class="text-muted" v-if="!verifiedCustomer">
            {{t['Products - Account Not Valid For Orders']}}
        </small>
    </div>
</template>
