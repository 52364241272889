
<template>
    <div>
        <vue-modaltor :visible="open" @hide="hideModal" :defaultWidth="'100%'">

            <div class="container product-modal">
                <div class="row">
                    <div class="col-md-12">
                        <h1>{{headline}}</h1>
                        <small class="text-muted" v-if="!verifiedCustomer">
                            {{t['Products - Account Not Valid For Orders']}}
                        </small>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 order-type-btns">
                        <button type="button" class="btn btn-default" v-for="(tab, index) in tabs" v-bind:disabled="(!accessToOrder && tab.requiresAccess)" v-bind:class="{ active: selectedtab == index, disabled: (!accessToOrder && tab.requiresAccess) }" :key="'tab' + index" :data-index="index" @click="openTab" v-html="tab.title"></button>
                    </div>
                </div>
                <div class="row">
                    <div v-bind:class="[showCheckout ? 'col-md-9' : 'col-md-12']">
                        <div class="row" v-for="(tab, index) in tabs" :key="'tabcontent' + index">
                            <div v-if="selectedtab == 0 && index == 0">
                                <variant-list :productid="productid" :productname="productname" :addtocartpopuparticletext="addtocartpopuparticletext"></variant-list>
                            </div>
                            <div v-else-if="selectedtab == 1 && index == 1">
                                <cut-sample-list :productid="productid" :productname="productname" :addtocartpopuparticletext="addtocartpopuparticletext"></cut-sample-list>
                            </div>
                            <div v-else-if="selectedtab == 2 && index == 2">
                                <hanger :productid="productid" :productname="productname" :addtocartpopuparticletext="addtocartpopuparticletext"></hanger>
                            </div>
                            <div v-else-if="selectedtab == 3 && index == 3">
                                <sample-card :productid="productid" :productname="productname" :addtocartpopuparticletext="addtocartpopuparticletext"></sample-card>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 checkout-preview" v-show="showCheckout">
                        <page-cart :checkout="checkout"></page-cart>
                    </div>
                </div>
                </div>

		</vue-modaltor>
    </div>
</template>

<script>
import Vue from "vue";
import VueModalTor from "vue-modaltor";
import { mapGetters, mapActions } from 'vuex';
import CutSampleList from './CutSampleList.vue';
import PageCart from './PageCart.vue';
import Hanger from './Hanger.vue';
import SampleCard from './SampleCard.vue';
import VariantList from './VariantList.vue';

export default {
    name: 'ProductModal',
    props: {
        productid: {
            type: Number,
            required: true
        },        
        productname: {
            type: String,
            required: true
        },
        checkout: {
            type: String
        },
        usertype: {
            type: String,
            required: true
        },
        addtocartpopuparticletext: {
            type: String,
        },
    },
    data: function() {
        return {
            tabs: []
        };
    },
    components: {
        CutSampleList,
        Hanger,
        SampleCard,
        VariantList,
		PageCart
    },
    computed: {
        ...mapGetters({
            t: 'translations',
            cart: 'cart',
            open : 'open',
            selectedtab: 'selectedtab'
        }),
        headline(){
            return this.t['Products - Product Modal Header'].replace('{0}', this.productname);
        },
        accessToOrder() {
            return this.usertype == "payingcustomer" || (this.usertype == "salesrep" && this.cart.customer.companyName);
        },
        verifiedCustomer() {
            return this.cart.customer.customerNo && this.cart.customer.customerNo !== "";
        },
        showCheckout() {
            if (this.checkout && this.cart.products) {
                return this.cart.products.length > 0;
            }
            return false;
        }
    },
    methods: {
        ...mapActions([
            'selectTab',
            'closeModal'
        ]),
        hideModal() {
            this.closeModal();
            document.body.classList.remove("modal-open");
        },
        openTab(e) {
            var index = Number(e.target.dataset.index);
            this.selectTab(index);
		}
    },
    mounted() {
        this.tabs = [
				{
                    title: this.t['Products - Product Modal Tab Order'],
                    requiresAccess: true
                },
                {
                    title: this.t['Products - Product Modal Tab Cut Sample'],
                    requiresAccess: false
                },
                {
                    title: this.t['Products - Product Modal Tab Hanger'],
                    requiresAccess: true
                },
                {
                    title: this.t['Products - Product Modal Tab Sample Card'],
                    requiresAccess: true
                }
            ];
        document.body.classList.remove("modal-open");
        this.closeModal();
    }
};
</script>
