<template>
    <div>
        <div class="form registration-form" v-if="!isloggedin && !showRegisterForm">
            <fieldset>
                <div class="row">
                    <div class="col-xs-12 col-md-6">
                        <form action="" method="post" @submit="registerForm" v-if="!passwordChange" data-vv-scope="registerform">
                            <div class="alert alert-danger" v-if="ajaxErrors.length">
                                <strong>{{t['Login - Errors In Form']}}</strong>
                                <ul>
                                    <li v-for="(error, index) in ajaxErrors" :key="index">{{ error }}</li>
                                </ul>
                            </div>
                            <div class="form-group">
                                <label for="emailaddressLabel">{{t['Common - Email']}}</label>
                                <input type="email" id="emailaddressLabel" name="emailaddress" class="form-control" autocomplete="email" v-validate.persist="'required|email'" v-bind:placeholder="t['Common - Email']" v-model="user.emailaddress" />
                                <span>{{ vErrors.first('registerform.emailaddress') }}</span>
                            </div>
                            <button type="submit" class="btn c1-bg c1-text">{{t['Common - Continue Label']}}</button>
                        </form>
                        <div class="has-borders inner" v-if="passwordChange">
                            <p class="heading"><span class="line">{{t['Common - Email']}}</span></p>
                            {{user.emailaddress}}
                        </div>
                    </div>
                       <div class="col-xs-12 col-md-6 has-borders inner registration-form__info-box" v-if="passwordChange">
                            <fieldset v-if="!passwordChangeCompleted">
                                <form action="" method="post" @submit="passwordForm" data-vv-scope="passwordform">
                                    <div class="alert alert-danger" v-if="ajaxErrors.length">
                                        <strong>{{t['Login - Errors In Form']}}</strong>
                                        <ul>
                                            <li v-for="(error, index) in ajaxErrors" :key="index">{{ error }}</li>
                                        </ul>
                                    </div>
                                    <div class="form-group">
                                        <label for="passwordLabel">{{t['Common - Password Label']}}</label>
                                        <input type="password" id="passwordLabel" name="password" class="form-control" autocomplete="off" v-bind:data-vv-as="t['Login - Password Complexity']" v-validate.persist="{ required: true, regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).{10,}$/ }" v-bind:placeholder="t['Common - Password Label']" v-model="user.password" ref="user.password" />
                                        <div><small class="text-muted">{{t['Login - Password Complexity']}}</small></div>
                                        <span>{{ vErrors.first('passwordform.password') }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label for="passwordConfirmLabel">{{t['Login - Password Confirm Label']}}</label>
                                        <input type="password" id="passwordConfirmLabel" name="passwordConfirm" class="form-control" autocomplete="off" v-validate.persist="'required|confirmed:user.password'" data-vv-as="password" v-bind:placeholder="t['Login - Password Confirm Label']" v-model="user.passwordConfirm" />
                                        <span>{{ vErrors.first('passwordform.passwordConfirm') }}</span>
                                    </div>
                                    <p><button type="submit" class="btn btn-primary password-btn c1-bg c1-text c1-borders">{{t['Common - Continue Label']}}</button></p>
                                </form>
                            </fieldset>
                            <div v-if="passwordChangeCompleted">
                                <p>{{t['Login - Password Set Confirmation']}}</p>
                                <p><a :href="loginlink" class="btn btn-default c1-bg c1-text">{{t['Login - Sign In Label']}}</a></p>
                            </div>
                        </div>
                    
                </div>
            </fieldset>

            <vue-modaltor :visible="userExistsUmbracoModal" @hide="hideModal" :defaultWidth="'500px'">
                <h6>{{t['Register - Account Registered Label']}}</h6>
                <p><a :href="loginlink" class="btn btn-default c1-bg c1-text">{{t['Login - Sign In Label']}}</a></p>
            </vue-modaltor>

            <vue-modaltor :visible="userExistsSalesForceModal" @hide="hideModal" :defaultWidth="'500px'">
                <h6>{{t['Register - Account Verification Label']}}</h6>
                <p><button type="button" @click="resendVerification" class="btn c2-bg c2-text c2-borders">{{t['Register - Account Resend Verification Label']}}</button></p>
                <p v-if="verificationResent">{{t['Register - Account Resend Verification Sent']}}</p>
            </vue-modaltor>
            
            <vue-modaltor :visible="verificationErrorModal" @hide="hideModal" :defaultWidth="'500px'">
                <h6>{{t['Register - Account Verification Error Label']}}</h6>
                <p><button type="button" @click="resendVerification" class="btn c2-bg c2-text c2-borders">{{t['Register - Account Resend Verification Label']}}</button></p>
                <p v-if="verificationResent">{{t['Register - Account Resend Verification Sent']}}</p>
            </vue-modaltor>
        </div>

        <register-form v-if="showRegisterForm" :email="user.emailaddress" :terms="terms" :gdpr="gdpr" :loginlink="loginlink"></register-form>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import apiService from '../services/apiservice';
import VueModalTor from "vue-modaltor";
import RegisterForm from './RegisterForm';
const queryString = require('query-string');

export default {
    name: 'RegisterOrSetPassword',
    props: {
        isloggedin: {
            type: Boolean,
            required: true
        },
        contentid: {
            type: Number,
            required: true
        },
        loginlink: {
            type: String
        },    
        terms: {
            type: String,
            required: true
        },
        gdpr: {
            type: String,
            required: true
        }
    },
    data: function () {
        return {
            user: {
                emailaddress: '',
                password: '',
                passwordConfirm: '',
                isnew: false
            },
            ajaxErrors: [],
            userExistsUmbracoModal: false,
            userExistsSalesForceModal: false,
            verificationErrorModal: false,
            verificationResent: false,
            passwordChange: false,
            passwordChangeCompleted: false,
            showRegisterForm: false
        }
    },
    components: {
        RegisterForm
    },
    computed: {
        ...mapGetters({
            t: 'translations',
            culture: 'culture'
        })
    },
    methods: {
        ...mapActions([
            'closeModal'
        ]),
        hideModal() {
            this.userExistsUmbracoModal = false;
            this.userExistsSalesForceModal = false;
            this.verificationErrorModal = false;
            this.closeModal();
            document.body.classList.remove("modal-open");
        },
        registerForm(e) {
            e.preventDefault();
            var self = this;

            self.$validator.validate('registerform.*').then((result) => {
                if (result) {
                    $(".loading-spinner").show();

                    apiService.hasValidUmbracoAccount(self.user.emailaddress).then(function (bothAccounts) {
                        if (bothAccounts.data) {
                            if (bothAccounts.data.Exists) {
                                $(".loading-spinner").hide();
                                self.userExistsUmbracoModal = true;
                                return;
                            }
                        } else {
                            $(".loading-spinner").hide();
                            return;
                        }

                        apiService.hasValidSalesForceAccount(self.user.emailaddress, self.culture).then(function (account) {
                            $(".loading-spinner").hide();
                            if (account.data) {
                                if (account.data.Exists && account.data.Success) {
                                    self.userExistsSalesForceModal = true;
                                    return;
                                } else if(account.data.Exists && !account.data.Success) {
                                    self.verificationErrorModal = true;
                                    return;
                                } else {
                                    self.showRegisterForm = true;
                                    return;
                                }
                            }
                        });
                    });
                }
            });
        },
        passwordForm(e) {
            e.preventDefault();
            var self = this, query = queryString.parse(location.search);

            self.$validator.validate('passwordform.*').then((result) => {
                if (result) {
                    $(".loading-spinner").show();

                    apiService.setPassword(query.uuid, self.user.isnew, self.user.emailaddress, self.user.password, self.user.passwordConfirm, self.culture).then(function (response) {
                        $(".loading-spinner").hide();
                        if (response && response.data) {
                            if (response.data.Success) {
                                self.passwordChangeCompleted = true;
                            }

                            if (response.data.Errors != null) {
                                self.ajaxErrors = response.data.Errors;
                            }
                        }
                    });
                }
            });
        },
        resendVerification() {
            var self = this;
            self.verificationResent = false;
            $(".loading-spinner").show();
            apiService.hasValidSalesForceAccount(self.user.emailaddress, self.culture).then(function (result) {
                $(".loading-spinner").hide();
                if (result && result.data) {
                    if (result.data.Exists && result.data.Success) {
                        self.verificationResent = true;
                    } else {
                        self.userExistsSalesForceModal = false;
                        self.verificationErrorModal = true;
                    }
                }
            });
        }
    },
    mounted() {
        var self = this, query = queryString.parse(location.search);

        if (query.cid) {
            apiService.getEmailFromContactId(query.cid).then(function (result) {
                if (result && result.data) {
                    self.user.emailaddress = result.data.Value;
                    self.user.isnew = true;
                    self.passwordChange = result.data.Value != null && result.data.Value !== "";
                }
            });
        } else if (query.uuid && /[0-9A-F]{8}-([0-9A-F]{4}-){3}[0-9A-F]{12}/ig.test(query.uuid)) {
            apiService.getEmailFromAccount(query.uuid).then(function (result) {
                if (result && result.data) {
                    self.user.emailaddress = result.data.Value;
                    self.passwordChange = result.data.Value != null && result.data.Value !== "";
                }
            });
        }        
    }
};
</script>
<style lang="scss">
</style>
