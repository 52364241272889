import * as types from './mutation-types.cart';
import * as store from './index.cart';

export default {
    [types.SAVE_CHOSEN_DELIVERY_ADDRESS](state, { index }){
        state.chosenDeliveryAddressIndex = index;
    },
    [types.ADD_NEW_DELIVERY_ADDRESS](state, {address}){
        state.cart.customer.deliveryAddresses.push(address);
    },
    [types.ADD_NEW_DELIVERY_PHONE](state, {phone}){
        var deliveryAddress = state.cart.customer.deliveryAddresses[state.chosenDeliveryAddressIndex];
        deliveryAddress.phone = phone;
    },
    [types.UPDATE_CART_QUEUE](state, { product}) {
        const record = state.cartQueue.find(p => p.Id === product.Id);
        if (record) {
            state.cartQueue[record] = product;
        }
    },
    [types.ADD_TO_CART_QUEUE](state, { product}) {
        state.cartQueue.push(product);
    },
    [types.REMOVE_FROM_CART_QUEUE](state, { productInQueue}) {
        var index = state.cartQueue.indexOf(productInQueue);
        if (index > -1) {
            state.cartQueue.splice(index, 1);
        }
    },
    [types.CUSTOMER_EXISTS_IN_BASKET](state){
        state.customerExistsInBasket = true;
    },
    [types.CUSTOMER_EXISTS_IN_CART](state){
        state.customerExistsInCart = true;
    },
    [types.CUSTOMER_ADDED_FROM_SALESFORCE](state){
        state.customerAddedFromSalesForce = true;
    },
    [types.CURRENT_CUSTOMER](state, { customer }) {
        state.cart.customer = customer;
    },
    [types.CURRENT_DELIVERY_ADDRESS_INDEX](state, { currentDeliveryAddressIndex }) {
        state.chosenDeliveryAddressIndex = currentDeliveryAddressIndex;
    },
    [types.EMPTY_CART](state, { cart }) {
        cart.products = [];
        state.cart = cart;
    },
    [types.RESET_STATE_KEEP_CUSTOMER](state) {
        var currentCustomer = state.cart.customer;
        var customerAddedFromSalesForce = state.customerAddedFromSalesForce;
        var chosenDeliveryAddressIndex = state.chosenDeliveryAddressIndex;
        Object.assign(state, store.getDefaultCartState());
        state.cart.customer = currentCustomer;
        state.customerExistsInCart = true;
        state.customerAddedFromSalesForce = customerAddedFromSalesForce;
        state.chosenDeliveryAddressIndex = chosenDeliveryAddressIndex;
    },
    [types.RESET_STATE](state) {
        Object.assign(state, store.getDefaultCartState());
    },
    [types.RESET_CUSTOMER_IN_CART](state) {
        state.cart.customer = {};
        state.customerExistsInCart = false;
    },
    [types.ADD_BASKETID_TO_CART](state, { basketId }) {
        state.cart.basketId = basketId;
    },
    [types.ADD_MARKING_TO_CART](state, { marking }){
        state.cart.goodsmarking = marking;
    },
    [types.ADD_COPACKING_TO_CART](state, { copacking }) {
        state.cart.copacking = copacking;
    },
    [types.ADD_PARTIAL_DELIVERY_ACCEPTED_TO_CART](state, { partialdeliveryaccepted }) {
        state.cart.partialdeliveryaccepted = partialdeliveryaccepted;
    },
    [types.ADD_REQUESTED_DELIVERY_DATE_TO_CART](state, { requesteddeliverydate }) {
        state.cart.requesteddeliverydate = requesteddeliverydate;
    },
    [types.ADD_CUSTOMER_ORDERNUMBER_TO_CART](state, { customerordernumber }){
        state.cart.customerordernumber = customerordernumber;
    },
    [types.ADD_TO_CART] (state, { product }) {
        const record = state.cart.products.find(p => p.Id === product.Id);
        if (!record) {
            state.cart.products.push(product);
        } else {
            state.cart.products[record] = product;
        }
    },
    [types.CHANGE_QUANTITY] (state, { payload }) {
        var product = payload.product;
        var purchaselimit = payload.purchaselimit;

        const record = state.cart.products.find(p => p.Id === product.Id);
        
        if (record) {
            if (payload.direction === 'increment') {

                if (purchaselimit && product.Quantity >= purchaselimit) {
                    $("#MaxNumberOfItemsAdded").animate({ display: "toggle" }, 1500);
                    return;
                }

                product.Quantity = product.Quantity + 1;
            } else {
                product.Quantity = product.Quantity - 1;
            }

            if (product.Quantity > 0) {
                state.cart.products[record] = product;
            } else {
                var index = state.cart.products.indexOf(record);
                if (index > -1) {
                    state.cart.products.splice(index, 1);
                }
            }
        }
    },
    [types.CHANGE_QUANTITY_AND_LENGTH] (state, { payload }) {
        var product = payload.product;
        var quantity = payload.quantity;
        var orderlength = payload.orderlength;
        var remark = payload.remark;
        
        const record = state.cart.products.find(p => p.Id === product.Id);

        product.Quantity = quantity;
        product.OrderLength = orderlength;
        product.Remark = remark;
        state.cart.products[record] = product;
    },
    [types.CHANGE_QUANTITY_AND_CUTMEASURE] (state, { payload }) {
        var product = payload.product;
        var quantity = payload.quantity;
        var cutmeasures = payload.cutmeasures;
        var remark = payload.remark;
        
        const record = state.cart.products.find(p => p.Id === product.Id);

        product.Quantity = quantity;
        product.CutMeasures = cutmeasures;
        product.Remark = remark;
        state.cart.products[record] = product;
    },
    [types.REMOVE_PRODUCT_FROM_CART] (state, { product }){
        var index = state.cart.products.indexOf(product);
        if (index > -1) {
            state.cart.products.splice(index, 1);
        }
    },
    [types.REMOVE_PRODUCT_FROM_CART_BY_PART_NO] (state, { partNo }){
        const record = state.cart.products.find(p => p.PartNo === partNo);
        var index = state.cart.products.indexOf(record);
        if (index > -1) {
            state.cart.products.splice(index, 1);
        }
    }
};