<script>
import { mapGetters } from 'vuex';
import apiService from "../services/apiservice";
import SampleItem from "./SampleItem";

export default {
    name: 'Hanger',
    props: {
        productid: {
            type: Number,
            required: true
        },        
        productname: {
            type: String,
            required: true
        },
        addtocartpopuparticletext: {
            type: String,
        },
    },
    data: function() {
        return {
            filterisloading : true,
            item : {},
            allowedNoOfSameSamples: 1000,
            allowedNoOfTotalSamples: 1000
        };
    },
    components: {
        SampleItem
    },
    computed: {
        ...mapGetters({
            t: 'translations',
            culture: 'culture'
        }),
        headline(){
            return this.t['Products - Hanger Header'].replace('{0}', this.productname);
        },
        textExists() {
            return this.t['Products - AddToCart Popup text'] != '';
        },
        articleTextExists() {
            return this.addtocartpopuparticletext != '';
        },
    },
    created() {
        var self = this;
        apiService.getHanger(this.productid, this.culture).then(function(response) {
            if (response !== false) {
                self.filterisloading = false;
                self.item = response.data;
            }
        });
    }
};
</script>
<template>
    <div>
        <div class="col-md-12">
            <h2>{{headline}}</h2>
        </div>
        <div class="col-md-12 modal-popup-text" v-if="textExists">
            <span>{{t['Products - AddToCart Popup text']}}</span>
        </div>
        <div class="col-md-12 modal-popup-text" v-if="articleTextExists">
            {{this.addtocartpopuparticletext}}
        </div>

        <div class="col-md-12" v-if="item">
            <span v-if="filterisloading">
                <i class="fa fa-cog fa-spin" style="font-size:24px"></i>
                {{t['Products - Loading products']}}
            </span>

            <div class="row product-row" v-if="!filterisloading">
                <sample-item :item="item" :showPrice="true" :allowedNoOfSameSamples="allowedNoOfSameSamples" :allowedNoOfTotalSamples="allowedNoOfTotalSamples" />
            </div>
        </div>
        <div class="col-md-12" v-else>
            {{t['Products - Non Available']}}
        </div>
    </div>
</template>