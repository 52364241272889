<script>
import { mapGetters, mapActions } from 'vuex';
import AddToCart from './AddToCart.vue';
import apiService from '../services/apiservice';

export default {
    name: 'Checkout',
    props: {
        orderconfirmationpage: {
            type: String,            
            required: true
        },
        fairproductspage: {
            type: String,
            required: true
        }
    },
    data: function() {
        return {
            checkout: {},
            orderissubmitted: false,
            haserrors: false
        };
    },
    components: {
        AddToCart
    },
    computed:  {
        ...mapGetters({
            cart: 'cart',
            t: 'translations',
            culture: 'culture'
        }),
        customerordernumber: {
            get: function(){
                return this.cart.customerordernumber;
            },
            set: function(newCustomerordernumber) {
                this.addCustomerOrderNumberToCart(newCustomerordernumber);
            }
        },
        goodsmarking: {
            get: function(){
                return this.cart.goodsmarking;
            },
            set: function(newMarking) {
                this.addMarkingToCart(newMarking);
            }
        },
        customer() {
            return this.cart.customer;
        },
        deliveryAddress() {
            return this.customer.deliveryAddresses[0];
        },
        customerDataExists(){
            if (this.customer.companyName) {
                return true;
            } else {
                return false;
            }
        },
        orderIsValid(){
            if (!this.customer.companyName) {
                return false;
            }

            if (!this.cart.products.length < 0) {
                return false;
            }

            return true;
        }
    },
    methods: {
        ...mapActions([
            'addMarkingToCart',
            'addCustomerOrderNumberToCart'
        ]),
        placeOrder() {
            var self = this;

            this.orderissubmitted = true;

            this.showSpinner();

            apiService.placeOrder(this.cart, 0, this.culture).then(function(result){
                if (result !== false) {
                    if (result.data) {
                        if (result.data.Status === "OK") {
                            location.href = self.orderconfirmationpage;
                        }
                    }
                } else {
                    $(".loading-spinner").hide();
                    self.haserrors = true;
                }
            });
        },
        showSpinner() {
            $(".loading-spinner").show();
        },
        goBack(){
            this.showSpinner();
            location.href = this.fairproductspage;
        }
    }
};
</script>

<template>
    <div>
        <!-- CONTENT -->
        <section class="content checkout">

            <!-- CONTAINER -->
            <div class="container">
                
                <div class="row">
                    <div class="col-md-12">
                        <button class="btn c1-bg c1-text back-btn" v-on:click="goBack()">{{t['Checkout - Back button']}}</button>

                        <h1>{{t['Checkout - Headline']}}</h1>
                    </div>
                </div>
                
                <!-- ROW -->
                <div class="row checkout__container">
                    <div class="checkout__container-section checkout__container-section--products">
                        <div class="row">
                        <div class="col-md-12">
                            <h3>{{t['Checkout - Products Headline']}}</h3>
                            <div class="table-responsive">
                                <table class="table table-condensed table-striped cartTable">
                                    <thead>
                                        <th><h6>{{t['Common - Name']}}</h6></th>
                                        <th><h6>{{t['Common - Article Number']}}</h6></th>
                                        <th><h6>{{t['Common - Type']}}</h6></th>                                
                                        <th><h6>{{t['Common - Quantity']}}</h6></th>
                                        <th></th>
                                    </thead>
                                    <tbody>
                                            <tr v-for="product in cart.products" v-bind:key="product.Id">
                                                <td class="text-left">{{ product.Name }} <span v-if="product.IsSample">{{product.ColorId}}</span></td>
                                                <td class="text-left">{{ product.PartNo }}</td>
                                                <td v-if="product.ProductType == 'Variant'"><img class="type-icon" src="/src/images/order-icon.svg" alt="Order" /></td>
                                                <td v-else-if="product.ProductType == 'CutSample'"><img class="type-icon" src="/src/images/cut-sample-icon.svg" alt="Cut sample" /></td>
                                                <td v-else-if="product.ProductType == 'Hanger'"><img class="type-icon" src="/src/images/hanger-icon.svg" alt="Hanger" /></td>
                                                <td v-else><img class="type-icon" src="/src/images/sample-card-icon.svg" alt="Sample card" /></td>
                                                <td class="text-left">{{ product.Quantity }}

                                                    <span v-if="product.OrderLength > 0">
                                                        {{t['Products - Pieces Abbreviation']}} x {{product.OrderLength}} {{product.Uom}} x {{product.Width}}
                                                    </span>

                                                </td>
                                                <td class="text-right"><add-to-cart :product="product" :show-quantity="false" :customer-required="false"></add-to-cart></td>
                                            </tr>
                                        </tbody>
                                </table>
                             </div>
                            </div>
                            </div>
                        </div>

                    <div class="checkout__container-section checkout__container-section--customer">
                        <div class="row">
                        <div class="col-md-12">
                            <h3>{{t['Checkout - Customer']}}</h3>

                            <div class="alert alert-warning" v-if="!customerDataExists">{{t['Checkout - Customer not added yet']}}</div>

                            <div v-show="customerDataExists">

                                <h5>{{customer.companyName}}</h5>

                                <h6>{{t['Checkout - Address']}}</h6>
                                {{deliveryAddress.addressRow1}}<br />
                                {{deliveryAddress.addressRow2}}<br />
                                {{deliveryAddress.postalNo}} {{deliveryAddress.city}}<br />

                                <div class="row row-projectName">
                                    <div class="col-md-4">

                                        <h6>{{t['Checkout - Project title']}}</h6>
                                        <input class="form-control" type="text" v-model="customerordernumber" maxlength="30" v-bind:placeholder="t['Checkout - Project title placeholder']" />

                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-md-4">
                                        <h6>{{t['Checkout - Recipient']}}</h6>
                                        {{customer.firstName}} {{customer.lastName}}
                                    </div>
                                    <div class="col-md-4">
                                        <h6>{{t['Common - Email']}}</h6>
                                        {{customer.email}}
                                    </div>
                                    <div class="col-md-4">
                                        <h6>{{t['Common - Phone']}}</h6>
                                        {{customer.phone}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-4">
                                        <h6>{{t['Common - Sales rep']}}</h6>
                                        {{customer.fieldSalesRep}}
                                    </div>
                                    <div class="col-md-4">
                                    </div>
                                    <div class="col-md-4">
                                    </div>
                                </div>
                                </div>
                            </div>
                         </div>
                        </div>
                        <div class="checkout__container-section checkout__container-section--marketing">
                            <div class="row">
                                <div class="col-md-4">
                                    <h3>{{t['Checkout - Notes']}}</h3>
                                    <h6>{{t['Checkout - Marking']}}</h6>
                                    <input class="form-control" type="text" v-model="goodsmarking" maxlength="30" />
                                </div>
                            </div>
                        </div>

                    </div>
                <!--// ROW -->

                <div class="panel panel-danger" v-if="haserrors">
                    <div class="panel-heading">
                        <h3 class="panel-title">{{t['Checkout - Order Error Message']}}</h3>
                    </div>
                    <div class="panel-body">
                        <i>Reference: {{cart.basketId}}</i>
                    </div>
                </div>

                <button type="button" class="btn c1-bg c1-text checkout-btn" v-show="orderIsValid" v-bind:disabled="orderissubmitted" v-on:click="placeOrder()">{{t['Checkout - Place order']}}</button>
            </div>
            <!--// CONTAINER -->

        </section>
        <!--// CONTENT -->
    </div>
</template>
