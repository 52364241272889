import * as types from './mutation-types.productmodal';

export default {
    selectTab({commit}, tabindex) {
            commit(types.SELECT_TAB,
                {
                    tabindex
                });
        },
    closeModal({commit}) {
        commit(types.CLOSE_MODAL);
    }
}