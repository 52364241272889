import jquery from 'jquery';
import jqueryvalidation from 'jquery-validation'
import jqueryvalidationunobtrusive from 'jquery-validation-unobtrusive';

import bootstrap from 'bootstrap-sass';
require("jasny-bootstrap/dist/js/jasny-bootstrap");
import lazysizes from 'lazysizes';
import lazysizesunveilhooks from 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import slick from 'slick-carousel';
import plugins from './plugins';
import productPage from './productPage';
import application from './application';
import jqueryajaxunobtrusive from "jquery-ajax-unobtrusive";
import 'waypoints/lib/jquery.waypoints.js';
import ekkolightbox from 'ekko-lightbox';
import jquerymatchheight from 'jquery-match-height';
import 'spin.js';
import 'spin.js/jquery.spin';
import modals from './modals';
import './readMoreJS.js';

import Vue from 'vue';
import store from './vue/store/index';
import MiniCart from './vue/components/MiniCart.vue';
import AddToCart from './vue/components/AddToCart.vue';
import Checkout from './vue/components/Checkout.vue';
import FairCheckout from './vue/components/FairCheckout.vue';
import OrderConfirmation from './vue/components/OrderConfirmation.vue';
import ProductList from './vue/components/ProductList.vue';
import ProductItem from './vue/components/ProductItem.vue';
import ProductCallToAction from './vue/components/ProductCallToAction.vue';
import VariantCallToAction from './vue/components/VariantCallToAction.vue';
import PageCart from './vue/components/PageCart.vue';
import Hanger from './vue/components/Hanger.vue';
import SampleCard from './vue/components/SampleCard.vue';
import FairProductList from './vue/components/FairProductList.vue';
import ProductModal from './vue/components/ProductModal.vue';
import SessionHandler from './vue/components/SessionHandler.vue';
import ClimateScreenCallToAction from './vue/components/ClimateScreenCallToAction.vue';
import LoginOrForgotPassword from './vue/components/LoginOrForgotPassword.vue';
import RegisterOrSetPassword from './vue/components/RegisterOrSetPassword.vue';
import RegisterForm from './vue/components/RegisterForm.vue';
import ProductPrice from './vue/components/ProductPrice.vue';
import OrderHistory from './vue/components/OrderHistory.vue';
import { mapActions } from 'vuex';
import VueModalTor from 'vue-modaltor';
import VeeValidate, { Validator } from 'vee-validate';
import VueNumeric from './vue/plugins/vue-numeric';
import Modal from './vue/components/Modal.vue';
import ProductSearchResult from './vue/components/ProductSearchResult.vue';
import messagesDe from './vue/localization/veevalidate/de.js';
import messagesEn from './vue/localization/veevalidate/en.js';
import messagesEs from './vue/localization/veevalidate/es.js';
import messagesKo from './vue/localization/veevalidate/ko.js';
import messagesNl from './vue/localization/veevalidate/nl.js';
import messagesRu from './vue/localization/veevalidate/ru.js';
import messagesSv from './vue/localization/veevalidate/sv.js';
import messagesZh from './vue/localization/veevalidate/zh_CN.js';

const veemessages = {
    de: messagesDe,
    en: messagesEn,
    es: messagesEs,
    ko: messagesKo,
    nl: messagesNl,
    ru: messagesRu,
    sv: messagesSv,
    zh: messagesZh
};

Vue.use(VeeValidate, {
    errorBagName: 'vErrors'
});

Vue.use(VueModalTor);

new Vue({
    el: '#site',
    data: {},
    components: {
        cart: MiniCart,
        addtocart: AddToCart,
        checkout: Checkout,
        orderconfirmation: OrderConfirmation,
        productlist: ProductList,
        productitem: ProductItem,
        productcalltoaction: ProductCallToAction,
        variantcalltoaction: VariantCallToAction,
        pagecart: PageCart,
        productmodal: ProductModal,
        hanger: Hanger,
        fairproductlist: FairProductList,
        sessionhandler: SessionHandler,
        climatescreencalltoaction: ClimateScreenCallToAction,
        loginorforgotpassword: LoginOrForgotPassword,
        registerorsetpassword: RegisterOrSetPassword,
        registerform: RegisterForm,
        faircheckout: FairCheckout,
        productprice: ProductPrice,
        samplecard: SampleCard,
        orderhistory: OrderHistory,
        vuenumeric: VueNumeric,
        modal: Modal,
        productsearchresult: ProductSearchResult
    },
    methods: {
        ...mapActions([
            'saveCultureAndTranslations'
        ])
    },
    store,
    created() {
        var el = document.getElementById('site');
        var culture = el.getAttribute('culture');
        var decimalseparator = el.getAttribute('decimalseparator');
        var systemTexts = el.getAttribute('systemtexts');
        var payload = {
            culture: culture,
            decimalseparator: decimalseparator,
            translations: systemTexts
        };

        var twoLetterCode = document.getElementsByTagName("html")[0].getAttribute("lang");
        Validator.localize(twoLetterCode, veemessages[twoLetterCode]);

        this.saveCultureAndTranslations(payload);
    }
});
