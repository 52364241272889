<template>
	<div v-show="showCheckout">
		<table class="table table-condensed table-striped">
			<tbody>
				<tr v-for="product in sortedProducts" v-bind:key="product.Id">
					<td class="text-left" v-if="product.ProductType == 'CutSample'">
						<span class="product-name">{{ product.Name }}</span> <span>{{product.ColorId}}</span>
					</td>
					<td class="text-left" v-else><span class="product-name">{{ product.Name }}</span></td>
					<td v-if="product.ProductType == 'Variant'"><img class="type-icon" src="/src/images/order-icon.svg" alt="Order" /></td>
					<td v-else-if="product.ProductType == 'CutSample'"><img class="type-icon" src="/src/images/cut-sample-icon.svg" alt="Cut sample"/></td>
					<td v-else-if="product.ProductType == 'Hanger'"><img class="type-icon" src="/src/images/hanger-icon.svg" alt="Hanger"/></td>
					<td v-else><img class="type-icon" src="/src/images/sample-card-icon.svg" alt="Sample card" /></td>
					<td class="text-right btn-group-xs" v-if="product.ProductType == 'Variant' && product.CartRule !== 'Cut measure'">{{formatDecimalSeparator(product.OrderLength)}} {{product.Uom}}</td>
					<td class="text-right btn-group-xs" v-else-if="product.ProductType == 'Variant' && product.CartRule == 'Cut measure'">{{getTotalCutMeasure(product.CutMeasures)}} {{product.Uom}}</td>
					<td class="text-right btn-group-xs" v-else>{{ product.Quantity }}</td>
				</tr>
			</tbody>
		</table>
		<a class="btn btn-primary checkoutCart c2-bg c2-text c2-borders" v-show="showCheckout" v-bind:disabled="checkoutIsClicked" v-on:click="goToCheckout()">{{t['Common - Go to checkout']}}</a>
	</div>
</template>
<script>
	import { mapActions, mapGetters } from 'vuex';
	import apiService from "../services/apiservice";

	export default {
		name: 'PageCart',
		props: {
			checkout: {
				type: String
			}
    	},
		data: function() {
			return {
				checkoutIsClicked: false
			};
		},
		computed: {
			...mapGetters({
				cart: 'cart',
				culture: 'culture',
				t: 'translations',
				decimalseparator: 'decimalseparator'
			}),
			showCheckout() {
				if (this.cart.products) {
                    return this.cart.products.length > 0;
				}

				return false;
            },
            sortedProducts() {
                return this.cart.products.slice().reverse();
            }
		},
		methods: {
			getTotalCutMeasure(cutmeasureJson) {
				var cutmeasures = JSON.parse(cutmeasureJson);
				if (cutmeasures) {
					var total = 0;
					for (var i = 0; i < cutmeasures.length; i++) {
						var measurement = cutmeasures[i];
						total += measurement.noOfPieces * measurement.cutLength;
					}
					
					return this.formatDecimalSeparator(total.toFixed(2));
				}
				return 0;
			},
			goToCheckout(){
				this.checkoutIsClicked = true;
				$(".loading-spinner").show();
				window.location.href = this.checkout;
			},
			formatDecimalSeparator(value) {
				return value.toString().replace('.', this.decimalseparator);
			}
		}
	};
</script>
<style lang="scss">
	.btn-group-xs {
        i.fa {
            margin: 0;
        }
    }
	.type-icon {
		width: 20px;
	}
</style>