import TranslationActions from './actions.translation';
import TranslationMutations from './mutations.translation';

export const getDefaultTranslationState = () => {
    return {
        translations: {},
        culture: '',
        decimalseparator: '',
        timestamp: ''
    }
} 

// initial state
const state = getDefaultTranslationState();

// getters
const getters = {
    culture: state => state.culture,
    translations: state => state.translations,
    decimalseparator: state => state.decimalseparator,
    timestamp: state => state.timestamp
};

export default {
    state: state,
    getters: getters,
    actions: TranslationActions,
    mutations: TranslationMutations
};