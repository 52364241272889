<script>
import { mapGetters, mapActions } from 'vuex';
import apiService from "../services/apiservice";

export default {
    name: 'ProductSearchResult',
    props: {
        searchterm: {
            type: String,            
            required: true
        },
        contentid: {
            type: Number,
            required: true
        }
    },
    data: function() {
        return {
            products: [],
            showAllProducts: false,
            searchPerformed: false
        };
    },
    computed: {
        ...mapGetters({
            culture: 'culture',
            t: 'translations'
        }),
        productRows(){
            var rows = [];
            var rowProducts = [];
            
            var noOfColumns = 4;

            for (let i = 0; i < this.products.length; i++) {
                const product = this.products[i];
                
                if (i > 0 && i % noOfColumns == 0) {
                    rows.push(rowProducts);
                    rowProducts = [];
                    rowProducts.push(product);
                } else {
                    rowProducts.push(product);
                }

                if (i == (this.products.length -1)) {
                    rows.push(rowProducts)
                }
            }

            return rows;
        }
    },
    methods: {
        searchProducts() {
            var self = this;
            apiService.searchProducts(this.searchterm, this.showAllProducts, this.contentid, this.culture).then(function(response) {
                if (response !== false) {
                    self.products = response.data;
                    self.searchPerformed = true;
                }
            });
        },
        searchAndShowAllProducts() {
            this.showAllProducts = true;
            this.searchProducts();
        }
    },
    mounted() {
        this.searchProducts();
    }
};
</script>
<template>
    <div>
        <div v-if="productRows.length > 0">
            <div v-for="(row, rowindex) in productRows" :key="'row' + rowindex" class="row">
                <div class="col-md-3 margin-bottom" v-for="(item, index) in row" :key="'product' + index">
                    <a :href="item.Url">
                        <img :src="item.Image" :alt="item.Name"/><br/>
                        {{item.Name}}
                    </a>
                </div>
            </div>
        </div>   

        <div class="row" v-if="!showAllProducts && products.length >= 4">
            <div class="col-md-12 text-center">
                <a class="btn btn-primary c2-bg c2-text c2-borders" @click="searchAndShowAllProducts">{{t['Search - Show more products']}}</a>            
            </div>
        </div>

        <div v-if="this.productRows.length == 0 && searchPerformed">
            <span>{{t['Search - No products match your search']}}</span>
        </div>
    </div>
</template>
