import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import cartModule from './index.cart';
import translationModule from './index.translation';
import productModalModule from './index.productmodal';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

// one store for entire application
export default new Vuex.Store({
    strict: debug,
    modules: {
        cartModule,
        translationModule,
        productModalModule
    },
    plugins: [createPersistedState()]
})