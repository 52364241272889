<template>
    <div class="form loginOrForgotPassword" v-show="!isloggedin">
        <div class="inner has-borders">
            <fieldset>
                <div class="row">
                    <div class="col-xs-12 col-sm-6 inner">
                        <legend>{{t['Login - Registration for order samples']}}</legend>
                        <!-- <legend>{{t['Login - Want To Sign Up As Customer']}}</legend> -->
                        <!-- <p>{{t['Login - Register To Be Able To Place Order']}}</p> -->
                        <p><a :href="registerlink" class="btn btn-default c1-bg c1-text">{{t['Common - Register Label']}}</a></p>
                        </br></br></br></br></br>
                        <legend>{{t['Login - Looking For Our Configuration Tool Or The Accoustic Lab']}}</legend>
                        <p><a :href="svenlink" class="btn btn-default c2-bg c2-text has-borders">{{t['Login - To Login Page For Tools']}}</a></p>
                    </div>
                        <div class="col-xs-12 col-sm-6 has-borders inner">
                            <fieldset>
                                <legend>{{legendHeaderLabel}}</legend>
                                <form action="" method="post" @submit="signIn">
                                    <div class="alert alert-danger" v-if="ajaxErrors.length">
                                        <strong>{{t['Login - Errors In Form']}}</strong>
                                        <ul>
                                            <li v-for="(error, index) in ajaxErrors" :key="index">{{ error }}</li>
                                        </ul>
                                    </div>
                                    <div class="form-group">
                                        <label for="emailaddressLabel">{{t['Common - Email']}}</label>
                                        <input type="email" id="emailaddressLabel" name="emailaddress" class="form-control" autocomplete="email" v-validate="'required|email'" v-bind:placeholder="t['Common - Email']" v-model="user.emailaddress" />
                                        <span>{{ vErrors.first('emailaddress') }}</span>
                                    </div>
                                    <div v-if="!forgottenPassword" class="form-group">
                                        <label for="passwordLabel">{{t['Common - Password Label']}}</label>
                                        <input type="password" id="passwordLabel" name="password" class="form-control" autocomplete="off" v-validate="'required'" v-bind:placeholder="t['Common - Password Label']" v-model="user.password" />
                                        <span>{{ vErrors.first('password') }}</span>
                                    </div>
                                    <p v-if="!forgottenPassword"><button type="button" class="btn btn-default forgotpassword" @click="passwordReset">{{t['Login - Forgotten Password Label']}}</button></p>
                                    <p><button type="submit" class="btn c2-bg c2-text c2-borders">{{t['Common - Continue Label']}}</button></p>
                                </form>
                            </fieldset>
                        </div>
                    </div>
                </fieldset>

                <vue-modaltor :visible="registerModal" @hide="hideModal" :defaultWidth="'400px'">
                    <h6>{{t['Login - Please Register Label']}}</h6>
                    <p>{{t['Login - Register To Be Able To Place Order']}}</p>
                    <p><a :href="registerlink" class="btn btn-default c1-bg c1-text">{{t['Common - Register Label']}}</a></p>
                </vue-modaltor>

                <vue-modaltor :visible="checkemailModal" @hide="hideModal" :defaultWidth="'400px'">
                    <h6>{{t['Common - Please Check Email Label']}}</h6>
                </vue-modaltor>
            </div>
        </div>
    </template>

    <script>
    import VueModalTor from "vue-modaltor";
    import { mapGetters, mapActions } from 'vuex';
    import apiService from '../services/apiservice';
    const queryString = require('query-string');

    export default {
        name: 'LoginOrForgotPassword',
        props: {
            registerlink: {
                type: String
            },
            contentid: {
                type: Number,
                required: true
            },
            isloggedin: {
                type: Boolean,
                required: true
            },
            redirectlink: {
                type: String
            },
            svenlink: {
                type: String
            }
        },
        data: function() {
            return {
                user: {
                    emailaddress: '',
                    password: '',
                },
                ajaxErrors: [],
                registerModal: false,
                checkemailModal: false,
                forgottenPassword: false,
                legendHeaderLabel: "",
            }
        },
        mounted() {
            this.legendHeaderLabel = this.t['Login - Sign In Label'];
        },
        computed: {
            ...mapGetters({
                cart: 'cart',
                customerExistsInCart: 'customerExistsInCart',
                t: 'translations',
                culture: 'culture'
            })
        },
        methods: {
            ...mapActions([
                'closeModal',
                'addCustomerToCart',
                'resetCartState'
            ]),
            hideModal() {
                this.registerModal = false;
                this.checkemailModal = false;
                this.closeModal();
                document.body.classList.remove("modal-open");
            },
            signIn(e) {
                e.preventDefault();
                var self = this;

                self.$validator.validateAll().then((result) => {
                    if (result) {
                        $(".loading-spinner").show();

                        if (self.forgottenPassword) {
                            apiService.resetPassword(self.user.emailaddress, self.contentid).then(function (result) {
                                $(".loading-spinner").hide();

                                if (result.data.NotRegistered) {
                                    document.body.classList.add("modal-open");
                                    self.registerModal = true;
                                    return;
                                }

                                if (result.data.Errors != null) {
                                    self.ajaxErrors = result.data.Errors;
                                    return;
                                }

                                if (result.data.Success) {
                                    self.checkemailModal = true;
                                    self.forgottenPassword = false;
                                    self.legendHeaderLabel = self.t['Login - Sign In Label'];
                                    return;
                                }
                            });
                            return;
                        }

                        apiService.login(self.user, self.culture).then(function (result) {
                            $(".loading-spinner").hide();

                            if (result.data.NotRegistered) {
                                document.body.classList.add("modal-open");
                                self.registerModal = true;
                                return;
                            }

                            if (result.data.Errors != null) {
                                self.ajaxErrors = result.data.Errors;
                                return;
                            }

                            if (result.data.Success) {

                                if (result.data.EmptyCart || self.customerInCartMismatch(result.data.Customer.email)) {
                                    self.resetCartState();
                                }

                                var payload = {
                                    customer: result.data.Customer,
                                    customerAddedFromSalesForce: false
                                }

                                self.addCustomerToCart(payload).then((result) => {
                                    if (self.redirectlink) {
                                        location.href = self.redirectlink;
                                    } else {
                                        location.reload();
                                    }
                                });

                                return;
                            }
                        });
                    }
                });
            },
            passwordReset(e) {
                e.preventDefault();
                this.legendHeaderLabel = this.t['Login - Forgotten Password Label'];
                this.forgottenPassword = true;
            },
            customerInCartMismatch(email) {
                return this.customerExistsInCart && this.cart.customer.email !== email;
            }
        },
        created() {
            var query = queryString.parse(location.search);
            if (query.signedout) {
                this.resetCartState();
                history.pushState(null, "", location.href.split("?")[0]);
            }
        }
    };
    </script>

    <style lang="scss">

    </style>
