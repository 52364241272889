<script>
import { mapActions, mapGetters } from 'vuex';
import apiService from "../services/apiservice";

export default {
    name: 'ProductPrice',
    props: {
        partno: {
            type: Number,
            required: true
        },
        uom: {
            type: String,
            required: true
        },
        noofdecimals: {
            type: Number
        }
    },
    data: function () {
        return {
            priceFormatted: "",
            numberOfDecimals: 0
        };
    },
    computed: {
        ...mapGetters({
            cart: 'cart'
        })
    },
    created() {
        var self = this;
        if (typeof (self.cart.customer) === "undefined" || !self.cart.customer.customerNo || !self.cart.customer.webCustomer) {
            return;
        }
        if (typeof (self.noofdecimals) === "number") {
            self.numberOfDecimals = self.noofdecimals;
        }
        apiService.getProductPrice(self.partno, self.cart.customer.customerNo, self.numberOfDecimals).then(function(result) {
            if (!result.data || result.data.price < 1) {
                return;
            }
            self.priceFormatted = result.data.priceFormatted;
        });
    }
};
</script>
<template>
    <span class="productItem-price" v-if="priceFormatted.length > 0">
        {{priceFormatted}}/{{uom}}
    </span>
</template>