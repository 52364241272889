<script>
import { mapGetters, mapActions } from 'vuex';
import LoginOrForgotPassword from './LoginOrForgotPassword';
import CutMeasureVariantItem from './CutMeasureVariantItem';
import NormalVariantItem from './NormalVariantItem';
import SampleOrHangerItem from './SampleOrHangerEdit';
import apiService from '../services/apiservice';
import Modal from './Modal';

export default {
    name: 'Checkout',
    props: {
        orderconfirmationpage: {
            type: String,
            required: true
        },
        registerlink: {
            type: String,
            required: true
        },
        resettemplate: {
            type: String
        },
        contentid: {
            type: Number
        },
        isloggedin: {
            type: Boolean,
            required: true
        },
        terms: {
            type: String,
            required: true
        }
    },
    data: function() {
        return {
            checkout: {},
            orderissubmitted: false,
            hasordererrors: false,
            haschosendeliveryaddresserrors: false,
            showLoginOrForgotPassword: true,
            initnewdeliveryaddress: false,
            newdeliveryphone: '',
            newdeliveryaddress: {
                addressRow1: '',
                addressRow2: '',
                postalNo: '',
                city: '',
                countryCode: '',
                companyName: '',
                phone: ''
            },
            userisloggedin: this.isloggedin,
            orderTotal: {},
            priceFailure: false,
            countries: [],
            cmpProductsInCart: [],
            editCutMeasure: false,
            editNormalVariant: false,
            editSampleOrHanger: false,
            productToEdit: {},
            agreetoterms: false
        };
    },
    components: {
        LoginOrForgotPassword,
        CutMeasureVariantItem,
        NormalVariantItem,
        SampleOrHangerItem,
        Modal
    },
    computed:  {
        ...mapGetters({
            cart: 'cart',
            t: 'translations',
            culture: 'culture',            
            customerExistsInBasket: 'customerExistsInBasket',
            customerExistsInCart: 'customerExistsInCart',
            chosenDeliveryAddressIndex: 'chosenDeliveryAddressIndex',
            customerAddedFromSalesForce: 'customerAddedFromSalesForce',
            decimalseparator: 'decimalseparator'
        }),
        customerordernumber: {
            get: function(){
                return this.cart.customerordernumber;
            },
            set: function(newCustomerordernumber) {
                this.addCustomerOrderNumberToCart(newCustomerordernumber);
            }
        },
        goodsmarking: {
            get: function(){
                return this.cart.goodsmarking;
            },
            set: function(newMarking) {
                this.addMarkingToCart(newMarking);
            }
        },
        copacking: {
            get: function(){
                return this.cart.copacking;
            },
            set: function(newCopacking) {
                this.addCopackingToCart(newCopacking);
            }
        },
        partialdeliveryaccepted: {
            get: function(){
                return this.cart.partialdeliveryaccepted;
            },
            set: function(newPartialdeliveryaccepted) {
                this.addPartialdeliveryacceptedToCart(newPartialdeliveryaccepted);
            }
        },
        requesteddeliverydate: {
            get: function(){
                return this.cart.requesteddeliverydate;
            },
            set: function(newRequesteddeliverydate) {
                this.addRequestedDeliveryDateToCart(newRequesteddeliverydate);
            }
        },
        customer() {
            return this.cart.customer;
        },
        customerDataExists(){
            if (this.customer.companyName) {
                return true;
            } else {
                return false;
            }
        },
        productDataExists(){
            return this.cart.products.length > 0;
        },
        orderIsValid(){

            var valid = this.productDataExists &&
                    this.customerDataExists && 
                    this.isloggedin &&
                    this.chosenDeliveryAddressIndex > -1;

            if (!valid) {
                return false;
            }

            var chosenDeliveryAddress = this.customer.deliveryAddresses[this.chosenDeliveryAddressIndex];
            if (!chosenDeliveryAddress.phone) {
                return false;
            }

            return true;
        },
        hasCharges() {
            return this.orderTotal.orderCharges && this.orderTotal.orderCharges.length > 0;
        },
        hasDiscounts() {
            return this.orderTotal.orderTotalDiscountAmount && this.orderTotal.orderTotalDiscountAmount.length > 0;
        },
        hasOrderRowDiscounts() {
            if (!this.orderTotal.lineValues || this.orderTotal.lineValues.length === 0) return false;
            for (var i = 0; i < this.orderTotal.lineValues.length; i++) {
                if (this.orderTotal.lineValues[i].totalDiscountAmount > 0) {
                    return true;
                }
            }
            return false;
        },
        hasManualFreight() {
            return this.orderTotal.manualFreight && this.orderTotal.manualFreight == 1;
        },
        productsInCart() {
            if (this.cmpProductsInCart.length == 0) {
                for (var i = 0; i < this.cart.products.length; i++) {
                    var product = this.cart.products[i];

                    var productName = '';
                    if ((product.ProductType == 'SampleCard' || product.ProductType == 'CutSample') && product.ColorId) {
                        productName = product.Name + ' ' + product.ColorId;
                    } else {
                        productName = product.Name;
                    }

                    var vm = {
                        name: productName,
                        partNo: product.PartNo,
                        productType: product.ProductType,
                        cartRule: product.CartRule,
                        cutMeasures: this.formatCutMeasures(product.CutMeasures),
                        uom: product.Uom,
                        orderLength: product.OrderLength,
                        quantity: product.Quantity
                    };

                    this.cmpProductsInCart.push(vm);
                }
            }

            return this.cmpProductsInCart;
        }
    },
    methods: {
        ...mapActions([
            'addMarkingToCart',
            'addCustomerOrderNumberToCart',
            'addCustomerToCart',
            'addNewDeliveryAddress',
            'saveChosenDeliveryAddress',
            'addCopackingToCart',
            'addPartialdeliveryacceptedToCart',
            'addRequestedDeliveryDateToCart',
            'closeModal',
            'removeFromCart',
            'addNewDeliveryPhone'
        ]),
        placeOrder() {
            var self = this;
            this.$validator.validate('terms').then((result) => {
                if (result) {
                    self.orderissubmitted = true;

                    self.showSpinner();

                    apiService.placeOrder(self.cart, self.chosenDeliveryAddressIndex, self.culture).then(function(result){
                        if (result !== false) {
                            if (result.data) {
                                if (result.data.Status === "OK") {
                                    location.href = self.orderconfirmationpage;
                                }
                            }
                        } else {
                            self.hideSpinner();
                            self.hasordererrors = true;
                        }
                    });

                    return;
                }
            });
        },
        showSpinner() {
            $(".loading-spinner").show();
        },
        hideSpinner() {
            $(".loading-spinner").hide();
        },
        saveNewDeliveryAddress() {
            var self = this;
            this.$validator.validate('newdeliveryaddress.*').then((result) => {
                if (result) {
                    self.addNewDeliveryAddress(self.newdeliveryaddress);
                    self.initnewdeliveryaddress = false;

                    var idx = self.cart.customer.deliveryAddresses.length - 1;
                    self.chooseDeliveryAddress(idx);

                    return;
                }
            });
        },
        chooseDeliveryAddress(idx) {
            this.saveChosenDeliveryAddress(idx).then((success) => {
                if (!success) {
                    this.haschosendeliveryaddresserrors = true;
                } else {
                    this.haschosendeliveryaddresserrors = false;
                    this.updateOrderTotal();
                }
            });            
        },
        isChosenDeliveryAddress(idx){
            return this.chosenDeliveryAddressIndex == idx;
        },
        saveDeliveryPhone() {
            var self = this;
            this.$validator.validate('newphone').then((result) => {
                if (result) {
                    self.addNewDeliveryPhone(self.newdeliveryphone);
                    return;
                }
            });
        },
        updateOrder() {
            var self = this;
            setTimeout(function () { self.updateOrderTotal() }, 50);
        },
        updateOrderTotal() {

            if (!this.cart.customer.customerNo) {
                return false;
            }

            var self = this;
            self.showSpinner();
            self.priceFailure = false;
            apiService.getOrderTotal(self.cart, self.chosenDeliveryAddressIndex, self.customerAddedFromSalesForce).then(function (result) {
                self.hideSpinner();
                if (result && result.data) {
                    self.orderTotal = result.data;
                } else {
                    self.priceFailure = true;
                }
            });
        },
        itemAvailable(idx) {
            if (!this.orderTotal.lineValues || this.orderTotal.lineValues.length === 0) return "";
            var lineNo = idx + 1;
            for (var i = 0; i < this.orderTotal.lineValues.length; i++) {
                if (this.orderTotal.lineValues[i].lineNo === lineNo) {
                    return this.orderTotal.lineValues[i].availabilityDate;
                }
            }
            return "";
        },
        itemPrice(idx) {
            if (!this.orderTotal.lineValues || this.orderTotal.lineValues.length === 0) return "";
            var lineNo = idx + 1;
            for (var i = 0; i < this.orderTotal.lineValues.length; i++) {
                if (this.orderTotal.lineValues[i].lineNo === lineNo) {
                    return this.orderTotal.lineValues[i].salesPriceFormatted;
                }
            }
            return "";
        },
        itemDiscount(idx) {
            if (!this.orderTotal.lineValues || this.orderTotal.lineValues.length === 0) return "";
            var lineNo = idx + 1;
            for (var i = 0; i < this.orderTotal.lineValues.length; i++) {
                if (this.orderTotal.lineValues[i].lineNo === lineNo) {
                    return this.orderTotal.lineValues[i].totalDiscountPercent;
                }
            }
            return "";
        },
        rowPrice(idx) {
            if (!this.orderTotal.lineValues || this.orderTotal.lineValues.length === 0) return "";
            var lineNo = idx + 1;
            for (var i = 0; i < this.orderTotal.lineValues.length; i++) {
                if (this.orderTotal.lineValues[i].lineNo === lineNo) {
                    return this.orderTotal.lineValues[i].lineAmountFormatted;
                }
            }
            return "";
        },
        totalCutMeasureQuantity(idx, uom){
            if (!this.orderTotal.lineValues || this.orderTotal.lineValues.length === 0) return "";
            var lineNo = idx + 1;
            for (var i = 0; i < this.orderTotal.lineValues.length; i++) {
                if (this.orderTotal.lineValues[i].lineNo === lineNo) {
                    return this.formatDecimalSeparator(this.orderTotal.lineValues[i].roundedQty) + " " + uom;
                }
            }
            return "";
        },
        getCountries() {
            var self = this;
            apiService.getCountries(this.culture).then((result) => {
                if (result) {         
                    for (var key in result.data) {
                        self.countries.push({
                            text: key,
                            value: result.data[key]
                        });
                    }
                }
            });
        },
        formatCutMeasures(cutmeasures) {
            if (cutmeasures) {
                return JSON.parse(cutmeasures);
            }

            return [];
        },
        formatDecimalSeparator(value) {
            return value.toString().replace('.', this.decimalseparator);
        },
        productInCart(idx) {
            return this.cart.products[idx];
        },
        initChangeQuantity(idx) {
            var product = this.productsInCart[idx];
            this.productToEdit = this.productInCart(idx);

            if (product.productType == 'Variant') {
                if (product.cartRule == 'Normal') {
                    this.editNormalVariant = true;
                } else {                    
                    this.editCutMeasure = true;
                }
            } else {
                this.editSampleOrHanger = true;
            }
        },
        removeProductFromCart(idx) {
            var product = this.productsInCart[idx];
            var self = this;
            this.removeFromCart(product).then(function(response){
                self.refreshCart();
            });
        },
        hideCutMeasureModal() {
            this.refreshCart();
            this.editCutMeasure = false;
            this.closeModal();
        },
        hideNormalVariantModal() {
            this.refreshCart();
            this.editNormalVariant = false;
            this.closeModal();
        },
        hideSampleOrHangerModal() {
            this.refreshCart();
            this.editSampleOrHanger = false;
            this.closeModal();
        },
        refreshCart(){
            this.cmpProductsInCart = [];
            this.updateOrderTotal();
        }
    },
    created() {
        var self = this;
        if (this.isloggedin && (this.customerExistsInBasket || this.customerExistsInCart)) {
            // User session is valid and customer is in vue state.
            this.showLoginOrForgotPassword = false;
        } else if (this.isloggedin && (!this.customerExistsInBasket || !this.customerExistsInCart)) {
            // User is logged in but customer is not in vue state.            
            apiService.getCustomerFromSession().then(function (result) {
                if (result !== false) {
                    var payload = {
                        customer: result.data,
                        customerAddedFromSalesForce: false
                    }
                    
                    self.addCustomerToCart(payload).then((result) => {
                        self.showLoginOrForgotPassword = false;
                    }); 
                } else {
                    // User misses valid session, force login
                    self.userisloggedin = false;
                    self.showLoginOrForgotPassword = true;
                }
            });
        } else {
            // User is not in session or in vue state.
            this.showLoginOrForgotPassword = true;
        }
    },
    mounted() {
        if (!this.showLoginOrForgotPassword) {
            this.updateOrderTotal();
        }

        this.getCountries();
    }
};
</script>

<template>
    <div>
        <!-- CONTENT -->
        <section class="content checkout">

            <!-- CONTAINER -->
            <div class="container">
              
                <h1>{{t['Checkout - Headline']}}</h1>

                <!-- ROW -->
                <div class="row checkout__container">
                    <div class="checkout__container-section checkout__container-section--products">
                        <div class="row">
                            <div class="col-md-12">
                                <h3>{{t['Common - Products']}}</h3>

                                <div class="alert alert-warning" v-if="!productDataExists">{{t['Checkout - No products added']}}</div>
                                <div class="alert alert-danger" v-if="priceFailure">{{t['Checkout - Price Fetch Failure']}}</div>
                                <div class="table-responsive">
                                    <table class="table table-condensed table-striped cartTable" v-if="productDataExists">
                                        <thead>
                                            <tr>
                                                <th><h6>{{t['Common - Name']}}</h6></th>
                                                <th><h6>{{t['Common - Type']}}</h6></th>
                                                <th><h6>{{t['Common - Quantity']}}</h6></th>
                                                <th v-show="userisloggedin"><h6>{{t['Common - AvailableDate']}}</h6></th>
                                                <th v-show="userisloggedin"><h6>{{t['Common - Price']}}</h6></th>
                                                <th v-show="hasOrderRowDiscounts"><h6>{{t['Common - Discount']}}</h6></th>
                                                <th v-show="userisloggedin"><h6>{{t['Checkout - Total']}}</h6></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(product, idx) in productsInCart" v-bind:key="product.partNo">
                                                <td class="text-left">{{ product.name }}</td>
                                                <td v-if="product.productType == 'Variant'"><img class="type-icon" src="/src/images/order-icon.svg" alt="Order" /></td>
                                                <td v-else-if="product.productType == 'CutSample'"><img class="type-icon" src="/src/images/cut-sample-icon.svg" alt="Cut sample" /></td>
                                                <td v-else-if="product.productType == 'Hanger'"><img class="type-icon" src="/src/images/hanger-icon.svg" alt="Hanger" /></td>
                                                <td v-else><img class="type-icon" src="/src/images/sample-card-icon.svg" alt="Sample card" /></td>
                                                <td v-if="product.cartRule == 'Cut measure'" class="text-left">
                                                    <div class="edit-container">
                                                        <ul>
                                                            <li v-for="(measurement, measurementidx) in product.cutMeasures" v-bind:key="measurementidx">
                                                                {{measurement.noOfPieces}} x {{formatDecimalSeparator(measurement.cutLength)}} {{product.uom}}
                                                            </li>
                                                        </ul>
                                                        <img class="edit-button" src="/src/images/edit-circle.svg" v-on:click="initChangeQuantity(idx)" />
                                                    </div>
                                                    <span class="total-cut-measure" v-show="userisloggedin">{{ totalCutMeasureQuantity(idx, product.uom) }} {{t['Checkout - Total']}}</span>

                                                </td>
                                                <td v-else-if="product.cartRule == 'Normal' && product.productType == 'Variant'" class="text-left">
                                                    {{product.orderLength}} {{product.uom}}
                                                    <img class="edit-button" src="/src/images/edit-circle.svg" v-on:click="initChangeQuantity(idx)" />
                                                </td>
                                                <td v-else class="text-left">
                                                    {{product.quantity}} {{t['Products - Pieces Abbreviation']}}
                                                    <img class="edit-button" src="/src/images/edit-circle.svg" v-on:click="initChangeQuantity(idx)" />
                                                </td>
                                                <td v-show="userisloggedin">{{ itemAvailable(idx) }}</td>
                                                <td v-show="userisloggedin">{{ itemPrice(idx) }}</td>
                                                <td v-show="hasOrderRowDiscounts">{{ itemDiscount(idx) }} %</td>
                                                <td v-show="userisloggedin">{{ rowPrice(idx) }}</td>
                                                <td class="text-right">
                                                    <button class="remove" v-on:click="removeProductFromCart(idx)"></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                               
                                <div v-if="productDataExists" class="row delivery-row">
                                    <div class="col-md-12 text-left" v-if="hasManualFreight">
                                        {{t['Checkout - Manual Freight Message']}}
                                    </div>
                                    <div class="col-md-12 text-left checkbox">
                                        <label class="checkbox-container">
                                            <input type="checkbox" class="checkbox" name="partialdeliveryaccepted" v-model="partialdeliveryaccepted" @change="updateOrder" />
                                            <span class="checkmark"></span>
                                            {{t['Checkout - Partial Delivery Accepted']}}
                                        </label>
                                    </div>
                                    <!--<div class="col-md-12 text-left checkbox">
                                        <label class="checkbox-container">
                                            <input type="checkbox" class="checkbox" name="copacking" v-model="copacking" @change="updateOrder" />
                                            <span class="checkmark"></span>
                                            {{t['Checkout - CoPackaging']}}
                                        </label>
                                    </div>-->
                                    <div class="col-md-4 requested-date">
                                        <label>
                                            <h6><span>{{t['Checkout - Requested DeliveryDate']}}</span></h6>
                                            <input type="date" class="form-control" name="requesteddeliverydate" v-model="requesteddeliverydate" pattern="\d{2,4}-\d{2}-\d{2,4}" @change="updateOrder" />
                                        </label>
                                        <span>{{t['Checkout - Delivery earliest possible']}}</span>
                                    </div>
                                </div>
                                <div v-if="hasCharges">
                                    <h6>{{t['Checkout - Charges']}}</h6>
                                    <div v-for="item in orderTotal.orderCharges" v-bind:key="item.charge" class="row charge">
                                        <div class="col-xs-12">
                                            <h4>
                                                {{ item.charge }}
                                            </h4>  
                                            <h4>{{ item.chargeAmountFormatted }}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="hasDiscounts" class="row discounts">
                                    <div class="col-xs-12">
                                        <h4>{{t['Checkout - Discounts']}}</h4>
                                        <h4>{{orderTotal.orderTotalDiscountAmountFormatted}}</h4>
                                    </div>
                                </div>
                                <div v-if="userisloggedin" class="row taxes">
                                    <div class="col-xs-12">
                                        <h4>{{t['Checkout - Taxes']}}</h4>
                                        <h4>{{orderTotal.taxAmountFormatted}}</h4>
                                    </div>
                                </div>
                                <div v-if="userisloggedin" class="row total-amount">
                                    <hr />
                                    <div class="col-xs-12">
                                        <h6>{{t['Checkout - Total']}}</h6>
                                        <h6>{{orderTotal.netOrderValueFormatted}} {{t['Checkout - Excl Tax']}}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <modal v-if="editCutMeasure" :width="400" @closemodal="hideCutMeasureModal">
                        <cut-measure-variant-item :item="productToEdit" :editMode="true" slot="content" />
                    </modal>

                    <modal v-if="editNormalVariant" @closemodal="hideNormalVariantModal">
                        <normal-variant-item :item="productToEdit" :editMode="true" slot="content" />
                    </modal>

                    <modal v-if="editSampleOrHanger" @closemodal="hideSampleOrHangerModal">
                        <sample-or-hanger-item :item="productToEdit" slot="content" />                        
                    </modal>

                    <login-or-forgot-password v-if="showLoginOrForgotPassword" :resettemplate="resettemplate" :contentid="contentid" :registerlink="registerlink" :isloggedin="userisloggedin"></login-or-forgot-password>

                    <div v-show="!showLoginOrForgotPassword" class="checkout__container-section checkout__container-section--customer">
                        <div class="row">
                            <div class="col-md-12">
                                <h3>{{t['Checkout - Customer']}}</h3>

                                <div class="alert alert-warning" v-if="!customerDataExists">{{t['Checkout - Customer not added yet']}}</div>

                                <h5>{{customer.companyName}}</h5>

                                <div class="row row-addresses">
                                    <div class="col-md-6">
                                        <h6>{{t['Checkout - Invoice Address']}}</h6>
                                        <div v-if="customer.invoiceAddress">
                                            {{customer.invoiceAddress.companyName}}<br />
                                            {{customer.invoiceAddress.addressRow1}}<br />
                                            {{customer.invoiceAddress.addressRow2}}<br />
                                            {{customer.invoiceAddress.postalNo}} {{customer.invoiceAddress.city}}<br />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                    </div>
                                </div>

                                <div class="row row-projectName">
                                    <div class="col-md-4">

                                        <h6>{{t['Checkout - Project title']}}</h6>
                                        <input class="form-control" type="text" v-model="customerordernumber" maxlength="30" v-bind:placeholder="t['Checkout - Project title placeholder']" />

                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-4">
                                        <h6>{{t['Common - Recipient']}}</h6>
                                        {{customer.firstName}} {{customer.lastName}}
                                    </div>
                                    <div class="col-md-4">
                                        <h6>{{t['Common - Email']}}</h6>
                                        {{customer.email}}
                                    </div>
                                    <div class="col-md-4">
                                        <h6>{{t['Common - Phone']}}</h6>
                                        {{customer.phone}}
                                    </div>
                                </div>

                                <div class="row checkout__delivery-item" v-bind:class="{ chosen : isChosenDeliveryAddress(index)}" v-for="(address, index) in customer.deliveryAddresses" v-bind:key="index">
                                    <div class="col-md-8">
                                        <h6>{{t['Checkout - Delivery Address']}} <i class="fa fa-check"></i></h6>
                                        {{address.companyName}}<br />
                                        {{address.addressRow1}}<br />
                                        {{address.addressRow2}}<br />
                                        {{address.postalNo}} {{address.city}}<br />
                                        {{address.phone}}<br>
                                        
                                        <div class="col-md-6" v-if="!address.phone && isChosenDeliveryAddress(index)">
                                            <div class="form-group">
                                                 <span class="alert-danger">{{t['Checkout - New Delivery Phone Text']}}</span>
                                                <h6 for="newphone">{{t['Common - Phone']}}</h6>
                                                <input type="text" v-model="newdeliveryphone" v-validate="'required'" class="form-control" maxlength="13" v-bind:data-vv-as="t['Common - Phone']" name="newphone" id="newphone">
                                                <span>{{ vErrors.first('newphone') }}</span>
                                            </div>
                                            <button type="button" class="btn c2-bg c2-text c2-borders" v-on:click="saveDeliveryPhone()">{{t['Common - Save']}}</button>
                                        </div>

                                    </div>
                                    <div class="col-md-4 text-right">
                                        <button type="button" class="btn c2-bg c2-text c2-borders" v-bind:disabled="isChosenDeliveryAddress(index)" v-on:click="chooseDeliveryAddress(index)">{{t['Common - Choose']}}</button>
                                    </div>
                                </div>

                                <div class="panel panel-danger margin-top" v-if="haschosendeliveryaddresserrors">
                                    <div class="panel-body">
                                        {{t['Checkout - Delivery Address Error Message']}}
                                    </div>
                                </div>

                                <div class="row checkout__delivery-adress" v-show="initnewdeliveryaddress">
                                    <div class="col-md-4">
                                        <form data-vv-scope="newdeliveryaddress">
                                            <div class="form-group">
                                                <h6 for="companyName">{{t['Common - Company Name']}}</h6>
                                                <input type="text" v-model="newdeliveryaddress.companyName" v-validate="'required'" class="form-control" v-bind:data-vv-as="t['Common - Company Name']" name="companyName" id="companyName">
                                                <span>{{ vErrors.first('newdeliveryaddress.companyName') }}</span>
                                            </div>
                                            <div class="form-group">
                                                <h6 for="addressRow1">{{t['Common - Address1']}}</h6>
                                                <input type="text" v-model="newdeliveryaddress.addressRow1" v-validate="'required'" class="form-control" v-bind:data-vv-as="t['Common - Address1']" name="addressRow1" id="addressRow1">
                                                <span>{{ vErrors.first('newdeliveryaddress.addressRow1') }}</span>
                                            </div>
                                            <div class="form-group">
                                                <h6 for="addressRow2">{{t['Common - Address2']}}</h6>
                                                <input type="text" v-model="newdeliveryaddress.addressRow2" class="form-control"  name="addressRow2" id="addressRow2">
                                            </div>
                                            <div class="form-group">
                                                <h6 for="postalNo">{{t['Common - Zipcode']}}</h6>
                                                <input type="text" v-model="newdeliveryaddress.postalNo" v-validate="'required'" maxlength="9" class="form-control" v-bind:data-vv-as="t['Common - Zipcode']" name="postalNo" id="postalNo">
                                                <span>{{ vErrors.first('newdeliveryaddress.postalNo') }}</span>
                                            </div>
                                            <div class="form-group">
                                                <h6 for="city">{{t['Common - City']}}</h6>
                                                <input type="text" v-model="newdeliveryaddress.city" v-validate="'required'" maxlength="30" class="form-control" v-bind:data-vv-as="t['Common - City']" name="city" id="city">
                                                <span>{{ vErrors.first('newdeliveryaddress.city') }}</span>
                                            </div>
                                            <div class="form-group dropdown">
                                                <label for="selectCountry">{{t['Common - Country']}}</label>
                                                <select class="form-control" v-model="newdeliveryaddress.countryCode" id="selectCountry" name="country" v-validate="'required'" v-bind:data-vv-as="t['Common - City']">
                                                    <option v-for="country in countries" v-bind:value="country.value" v-bind:key="country.value">
                                                        {{ country.text }} 
                                                    </option>
                                                </select>
                                                <span>{{ vErrors.first('newdeliveryaddress.country') }}</span>
                                            </div>
                                            <div class="form-group">
                                                <h6 for="phone">{{t['Common - Phone']}}</h6>
                                                <input type="text" v-model="newdeliveryaddress.phone" v-validate="'required'" class="form-control" maxlength="13" v-bind:data-vv-as="t['Common - Phone']" name="phone" id="phone">
                                                <span>{{ vErrors.first('newdeliveryaddress.phone') }}</span>
                                            </div>
                                        </form>
                                        <button type="button" class="btn c2-bg c2-text c2-borders" v-on:click="saveNewDeliveryAddress()">{{t['Checkout - Save Address']}}</button>
                                    </div>
                                </div>

                                <button type="button" class="btn c2-bg c2-text c2-borders checkout-btn delivery-btn" v-bind:disabled="initnewdeliveryaddress" v-on:click="initnewdeliveryaddress = true">{{t['Checkout - Add New Address']}}</button>
                            </div>
                        </div>
                    </div>

                    <div v-show="!showLoginOrForgotPassword" class="checkout__container-section checkout__container-section--marketing">
                        <div class="row">
                            <div class="col-md-4">
                                <h3>{{t['Checkout - Notes']}}</h3>
                                <h6>{{t['Checkout - Marking']}}</h6>
                                <input class="form-control" type="text" v-model="goodsmarking" maxlength="30" />
                            </div>
                        </div>
                    </div>
                    
                    <div v-show="!showLoginOrForgotPassword" class="checkout__container-section checkout__container-section--terms">
                        <div class="row">
                            <div class="col-md-12">                    
                                <div class="checkbox">
                                    <label class="checkbox-container" for="checkboxTerms">
                                        <input type="checkbox" class="checkbox" id="checkboxTerms" name="terms" value="true" v-validate="'required'" v-bind:data-vv-as="t['Register - Terms Validation']" v-model="agreetoterms" />
                                        <span class="checkmark"></span>{{t['Register - Terms Pretext']}} <a v-bind:href="terms" target="_blank">{{t['Register - Terms Linktext']}}</a>{{t['Register - Terms Posttext']}}
                                    </label>
                                    <span class="alert-danger">{{ vErrors.first('terms') }}</span>
                                </div>               
                            </div>
                        </div>
                    </div>

                </div>
                <!--// ROW -->

                <div class="panel panel-danger" v-if="hasordererrors">
                    <div class="panel-heading">
                        <h3 class="panel-title">{{t['Checkout - Order Error Message']}}</h3>
                    </div>
                    <div class="panel-body">
                        <i>Reference: {{cart.basketId}}</i>
                    </div>
                </div>

                
                <button type="button" class="btn c1-bg c1-text checkout-btn" v-show="orderIsValid" v-bind:disabled="orderissubmitted" v-on:click="placeOrder()">{{t['Checkout - Place order']}}</button>
            </div>
            <!--// CONTAINER -->

        </section>
        <!--// CONTENT -->
    </div>
</template>

<style lang="scss" scoped>
    .input-quantity {
        width: 45px;
    }
</style>
