<template>
    <div>
        <img v-if="!editMode" :src="thumbnailImageUrl" v-bind:class="{clickable : !hasPlaceHolderImage}" class="preview-thumbnail" :alt="item.ColorId" v-on="!hasPlaceHolderImage ? { click: showPreview } : null" />
        {{item.ColorId}}&nbsp;<span v-if="item.Discontinued">{{t['Products - Product Discontinued']}}</span><strong v-if="item.New">{{t['Products - New product variant']}}</strong>
        <div v-if="showForm" class="row normal-variant">
            <div class="col-md-12">
                {{t['Common - Quantity']}}: <vue-numeric v-bind:decimal-separator="decimalseparator" :precision="1" :min="0" v-model="orderlength"></vue-numeric> {{item.Uom}}
            </div>
            <div class="col-md-12">
                <span>{{t['Products - Remark']}}: </span> <input type="text" maxlength="30" class="remark" v-model="remark" />
            </div>
            <div class="col-md-12">
                <button class="btn btn-default btn c1-bg c1-text" :disabled="buttonDisabled" v-bind:class="{ disabled: buttonDisabled }" @click="addToCart()">{{buttonText}}</button>
            </div>
        </div>
        <div class="error-box alert alert-danger" v-if="hasDirtyCart">
            {{t['Products - Error Another Business Area']}}
        </div>
        <button v-if="!showForm" class="btn btn-default btn c1-bg c1-text" @click="addToCartInitialized = !addToCartInitialized">{{t['Products - Select Quantity']}}</button>
        <span v-if="showItemIsUpdatedMessage">{{t['Products - Item Is Updated']}}</span>
        <i v-if="!editMode" v-show="!hasPlaceHolderImage" class="fa fa-search-plus" @click="showPreview"></i>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import imagepreview from '../../imagepreview';
import cartvalidator from '../../cartvalidator';
import VueNumeric from '../plugins/vue-numeric';

export default {
    name: 'NormalVariantItem',
    props: {
        item: {
            type: Object,
            required: true
        },        
        editMode: {
            type: Boolean
        }
    },
    data: function() {
        return {
            orderlength: 0,
            hasDirtyCart: false,
            addToCartInitialized: false,
            hasPlaceHolderImage: false,
            showItemIsUpdatedMessage: false,
            cmpRemark: ''
        };
    },
    components: {
        VueNumeric
    },
    computed: {
        ...mapGetters({
            t: 'translations',
            decimalseparator: 'decimalseparator',
            cart: 'cart'
        }),
        thumbnailImageUrl() {
            if (this.item.ThumbnailImage) {
                return this.item.ThumbnailImage;
            } else {
                this.hasPlaceHolderImage = true;
                return "/src/images/placeholder-182x140.png";
            }
        },
        largeImageUrl() {
            if (this.item.LargeImage) {
                return this.item.LargeImage;
            } else {
                return "/src/images/placeholder-847x424.png";
            }
        },
        productInCart(){
            return this.cart.products.find(p => p.Id === this.item.Id)
        },
        showForm() {
            return (this.productInCart && this.productInCart.OrderLength > 0) 
            || this.addToCartInitialized;
        },
        buttonText() {
            if (this.editMode) {
                return this.t['Products - Update cart'];
            } else {
                return this.t['Products - Add to cart'];
            }
        },
        buttonDisabled() {
            return this.orderlength < 1;
        },
        remark: {
            set: function(val) {
                this.cmpRemark = val
            },
            get: function() {
                if (this.cmpRemark == '' && this.productInCart) {
                    this.cmpRemark = this.productInCart.Remark;
                }
                return this.cmpRemark;
            }
        }
    },
    methods: {
        ...mapActions([
            'saveToCart',
            'changeQuantityAndLength'
        ]),
        addToCart(){
            if (cartvalidator.cartHasProductFromAnotherBusinessArea(this.item)) {
                this.hasDirtyCart = true;
                return;
            } else {
                this.hasDirtyCart = false;
            }

            var quantity;
            if (this.orderlength > 0) {
                quantity = 1;
            } else {
                quantity = 0;
            }

            if (this.productInCart) {
                var payload = {
                    'product' : this.productInCart,
                    'orderlength' : this.orderlength,
                    'quantity' : quantity,
                    'remark' : this.remark
                };     
                this.changeQuantityAndLength(payload);

                if (this.editMode) {
                    this.showItemIsUpdatedMessage = true;
                    var self = this;
                    setTimeout(function() {
                        self.showItemIsUpdatedMessage = false;
                    }, 1500);
                }
            } else {
                this.item.OrderLength = this.orderlength;
                this.item.Quantity = quantity;
                this.item.Remark = this.remark;
                this.saveToCart(this.item).then(function(response){});
            }
        },
        showPreview: function(e){
            imagepreview.showLargeImage(e, this.item.ColorId, this.largeImageUrl);
        }
    }, 
    mounted() {
        if (this.productInCart) {
            this.orderlength = this.productInCart.OrderLength;
        }
    }
};
</script>

<style lang="scss" scoped>
    .remark {
        width: 100% !important;
        text-align: left !important;
    }
</style>
