
<template>
    <div class="cut-measure">
        <img v-if="!editMode" :src="thumbnailImageUrl" class="preview-thumbnail" :alt="item.ColorId" @click="showPreview" />
        <div v-if="!editMode" class="row">
            <div class="col-md-12">
                {{item.ColorId}}&nbsp;<span v-if="item.Discontinued">{{t['Products - Product Discontinued']}}</span><strong v-if="item.New">{{t['Products - New product variant']}}</strong>
            </div>
        </div>
        <div v-show="showForm" class="row cut-input" v-for="(row, index) in cutmeasures" v-bind:key="index">
            <div>
                <span>{{t['Common - Quantity']}}:</span> <vue-numeric v-model="row.noOfPieces" :min="1"></vue-numeric>
            </div>
            <div >
                <span>{{t['Products - Cut measure']}}:</span> <vue-numeric v-model="row.cutLength" v:bind:decimal-separator="decimalseparator" :precision="2"></vue-numeric> {{item.Uom}}
            </div>
            <div>
                <button @click="removeRow(index)" class="remove"></button>

            </div>
        </div>
        <div v-show="showForm" class="row">
            <div class="col-md-12 btn-group-xs">
                <button class="btn plus" @click="addRow()"><i class="close fa fa-plus"></i></button>
            </div>
        </div>
        <div v-show="showForm && cutmeasures.length > 0" class="row">
            <div class="col-md-12">
                <span>{{t['Products - Remark']}}: </span> <input type="text" maxlength="30" class="remark" v-model="remark" />
            </div>
        </div>
        <div class="error-box alert alert-danger" v-if="hasDirtyCart">
            {{t['Products - Error Another Business Area']}}
        </div>
        <div v-show="!showForm" class="row">
            <div class="col-md-12">
                <button class="btn btn-default btn c1-bg c1-text" @click="initForm">{{t['Products - Select cut measure']}}</button>
            </div>
        </div>
        <div class="row total-row">
            <div v-show="showForm" class="col-xs-12 col-md-6">
                <span v-if="cutmeasures.length > 0"> {{t['Products - Total length']}}: {{formattedTotalCutmeasure}} {{item.Uom}}</span>
            </div>
            <div class="col-xs-12 col-md-6 text-right">
                <button v-show="showUpdateButton" class="btn btn-default btn c1-bg c1-text pull-right" v-bind:disabled="!cutmeasureValueValid" @click="updateCart">{{t['Products - Update cart']}}</button>
                <span v-if="showItemIsUpdatedMessage">{{t['Products - Item Is Updated']}}</span>
            </div>
        </div>
        <i v-if="!editMode" class="fa fa-search-plus" @click="showPreview"></i>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import imagepreview from '../../imagepreview';
import cartvalidator from '../../cartvalidator';
import VueNumeric from '../plugins/vue-numeric';

export default {
    name: 'CutMeasureVariantItem',
    props: {
        item: {
            type: Object,
            required: true
        },
        editMode: {
            type: Boolean
        }
    },
    data: function() {
        return {
            hasDirtyCart: false,
            initFormInitialized: false,
            removeRowInitialized: false,
            cmpCutmeasures: [],   
            cmpRemark: '',
            showItemIsUpdatedMessage: false
        };
    },
    components: {
        VueNumeric
    },
    computed: {
        ...mapGetters({
            t: 'translations',
            decimalseparator: 'decimalseparator',
            cart: 'cart'
        }),
        thumbnailImageUrl() {
            if (this.item.ThumbnailImage) {
                return this.item.ThumbnailImage;
            } else {
                return "/src/images/placeholder-182x140.png";
            }
        },
        largeImageUrl() {
            if (this.item.LargeImage) {
                return this.item.LargeImage;
            } else {
                return "/src/images/placeholder-847x424.png";
            }
        },
        productInCart(){
            return this.cart.products.find(p => p.Id === this.item.Id)
        },
        showForm() {
            return this.cutmeasures.length > 0 || this.initFormInitialized;
        },
        showUpdateButton() {
            return this.initFormInitialized || this.removeRowInitialized;
        },
        totalQuantity() {
            return this.cutmeasures.reduce( function(a, b){
                return a + b['noOfPieces'];
            }, 0);
        },
        totalCutmeasure() {
            var total = 0;
            for (var i = 0; i < this.cutmeasures.length; i++) {
                var measurement = this.cutmeasures[i];
                total += measurement.noOfPieces * measurement.cutLength;
            }

            return total.toFixed(2);
        },

        cutmeasureValueValid(){
            return this.cutmeasures.length == 0 || this.totalCutmeasure >= 1;
        },
        cutmeasures() {
            if (this.cmpCutmeasures.length == 0 && this.productInCart && !this.removeRowInitialized) {
                this.cmpCutmeasures = JSON.parse(this.productInCart.CutMeasures);
                this.initFormInitialized = true;
            }             
            return this.cmpCutmeasures;
        },
        formattedTotalCutmeasure() {
            return this.totalCutmeasure.toString().replace('.', this.decimalseparator);
        },
        remark: {
            set: function(val) {
                this.cmpRemark = val
            },
            get: function() {
                if (this.cmpRemark == '' && this.productInCart) {
                    this.cmpRemark = this.productInCart.Remark;
                }
                return this.cmpRemark;
            }
        }
    },
    methods: {
        ...mapActions([
            'saveToCart',
            'changeQuantityAndCutmeasures'
        ]),
        initForm() {
            this.addRow();
            this.initFormInitialized = true;
        },
        addRow() {
            this.cutmeasures.push({
                    noOfPieces: 1,
                    cutLength: 0.00
                });
        },
        removeRow(idx) {
            this.removeRowInitialized = true;
            this.cutmeasures.splice(idx, 1);
        },
        updateCart(){
            if (cartvalidator.cartHasProductFromAnotherBusinessArea(this.item)) {
                this.hasDirtyCart = true;
                return;
            } else {
                this.hasDirtyCart = false;
            }

            if (this.productInCart) {
                if (this.totalQuantity == 0) {
                    this.remark = '';
                }
                var payload = {
                    'product' : this.productInCart,
                    'quantity' : this.totalQuantity,
                    'remark' : this.remark,
                    'cutmeasures' : JSON.stringify(this.cutmeasures)
                };     
                this.changeQuantityAndCutmeasures(payload);

                if (this.editMode) {
                    this.showItemIsUpdatedMessage = true;
                    var self = this;
                    setTimeout(function() {
                        self.showItemIsUpdatedMessage = false;
                    }, 1500);
                }

                if (this.totalQuantity == 0) {
                    this.initFormInitialized = false;
                    this.removeRowInitialized = false;
                }

            } else {
                this.item.Quantity = this.totalQuantity,
                this.item.CutMeasures = JSON.stringify(this.cutmeasures);
                this.item.Remark = this.remark;
                this.saveToCart(this.item).then(function(response){});
            }
        },
        showPreview: function(e){
            imagepreview.showLargeImage(e, this.item.ColorId, this.largeImageUrl);
        }
    }
};
</script>

<style lang="scss" scoped>
    input {
        width: 45px;

        &.remark {
            width: 100%;
        }
    }
    .preview-thumbnail {
        cursor: pointer;
    }
    .fa-search-plus {
        position: absolute;
        top: 5px;
        right: 15px;
        font-size: 25px;
        cursor: pointer;
        color: #fff;
    }
    .preview {
        margin-bottom: 20px;
        img {
            cursor: pointer;
        }
        .close-preview {
            position:absolute;
            z-index: 1;
            font-size: 40px;
            top: 10px;
            right: 20px;
            cursor: pointer;
            color: #fff;
        }
        .color {
            position: absolute;
            bottom: 0;
            z-index: 1;
            right: 25px;
            font-weight: bold;
            font-size: 30px;
            color: #fff;
        }
    }
    .error-box { 
        &.alert {
            margin-top: 10px;
            margin-bottom: -20px;
            padding: 5px;
        }
    }
</style>
