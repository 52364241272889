<script>
import { mapActions, mapGetters } from 'vuex';
import apiService from '../services/apiservice';
import ProductItem from './ProductItem';
import AddToCart from './AddToCart';

export default {
    name: 'FairProductList',
    props:{
        currentcategory: {
            type: String
        },
        currentcategorycode: {
            type: String
        },
        samplecards: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            products: [],
            newproducts: [],
            filterisloading: false
        };
    },
    components: {
        AddToCart
    },
    computed: {
        ...mapGetters({
            t: 'translations',
            culture: 'culture'
        })
    },
    mounted() {
        this.filterisloading = true;
        var self = this;

        if (self.samplecards) {
            apiService.getSampleCards(this.currentcategory, this.currentcategorycode, this.culture).then(function(response){
                if (response !== false && response.data !== null){

                    var families = response.data;
                    for (var family in families) {
                        if (families.hasOwnProperty(family)) {
                            self.products.push([family, families[family]]);
                        }
                    }
                }

                self.filterisloading = false;
            });
        } else {
            apiService.getFairProducts(this.currentcategory, this.currentcategorycode, this.culture).then(function(response){
                if (response !== false && response.data !== null){

                    self.newproducts = response.data.Item1;

                    var alphabeticalGroups = response.data.Item2;
                    var sortedProducts = [];
                    for (var alphabeticalGroup in alphabeticalGroups) {
                        if (alphabeticalGroups.hasOwnProperty(alphabeticalGroup)) {
                            self.products.push([alphabeticalGroup, alphabeticalGroups[alphabeticalGroup]]);
                        }
                    }           
                }

                self.filterisloading = false;
            });
        }
    }
};
</script>
<template>
    <div class="container">
        <div class="row" v-if="filterisloading">
            <div class="wide-col col-xs-12">
                 <i class="fa fa-cog fa-spin" style="font-size:24px"></i>
                {{t['Products - Loading products']}}
            </div>
        </div>
        <div class="row" v-else>
            <div class="wide-col col-xs-12">

                <div v-if="newproducts.length > 0">
                    <h2>{{t['Products - News']}}</h2>
                    <table class="table table-condensed table-striped">
                        <tr v-for="(product, index) in newproducts" :key="index">
                            <td class="col-xs-6 col-sm-8 col-lg-10">{{product.Name}}</td>
                            <td class="col-xs-6 col-xs-4 col-lg-2">
                            <add-to-cart :product="product"></add-to-cart>
                            </td>
                        </tr>
                    </table>
                </div>
                <hr />
                <div v-if="products.length > 0">
                    <h2 v-if="!samplecards">{{t['Products - Alphabetical']}}</h2>

                    <div v-for="(item, index) in products" :key="index">
                        <h3>{{item[0]}}</h3>

                        <table class="table table-condensed table-striped">
                            <tr v-for="(product, productindex) in item[1]" :key="productindex">
                                <td class="col-xs-6 col-sm-8 col-lg-10">{{product.Name}}</td>
                                <td class="col-xs-6 col-xs-4 col-lg-2">
                                <add-to-cart :product="product"></add-to-cart>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
