//Image slider
$(document).ready(function createSlickThumb() {

    if ($('.slides-img').length) {
        var $slider = $('.slides-img')
            .on('init', function (slick) {
                $('.slides-img').fadeIn(1000);
            })
            .slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                autoplay: false,
                mobileFirst: true,
                adaptiveHeight: true,
                lazyLoad: 'ondemand',
                asNavFor: '.slides-thmb'
            });

        var $slider2 = $('.slides-thmb')
            .on('init', function (slick) {
                $('.slides-thmb').fadeIn(1000);
            })
            .slick({
                slidesToShow: $(window).width() < 767 ? 3 : 7,
                slidesToScroll: 1,
                lazyLoad: 'ondemand',
                asNavFor: '.slides-img',
                mobileFirst: true,
                adaptiveHeight: false,
                accessibility: false,
                dots: false,
                arrows: true,
                prevArrow: "<button type='button' class='slick-prev pull-left'><i class='fa fa-angle-left' aria-hidden='true'></i></button>",
                nextArrow: "<button type='button' class='slick-next pull-right'><i class='fa fa-angle-right' aria-hidden='true'></i></button>",
                centerMode: false,
                focusOnSelect: true
            });

        //remove active class from all thumbnail slides
        $('.slides-thmb .slick-slide').removeClass('slick-active');

        //set active class to first thumbnail slides
        $('.slides-thmb .slick-slide').eq(0).addClass('slick-active');

        // On before slide change match active thumbnail to current slide
        $('.slides-img').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            var mySlideNumber = nextSlide;
            $('.slides-thmb .slick-slide').removeClass('slick-active');
            $('.slides-thmb .slick-slide').eq(mySlideNumber).addClass('slick-active');
        });
    }
});

//Product name cs icon 
$(document).ready(function addProductIcon() {
    var productName = $(".productPage__name");
    var productNameText = productName.text().toLowerCase();
    if (productNameText.match('harmony')) {
        productName.addClass('harmony');
    } else if (productNameText.match('luxous')) {
        productName.addClass('luxous');
    } else if (productNameText.match('obscura')) {
        productName.addClass('obscura');
    } else if (productNameText.match('solaro')) {
        productName.addClass('solaro');
    } else if (productNameText.match('tempa')) {
        productName.addClass('tempa');
    } else if (productNameText.match('xsect')) {
        productName.addClass('xsect');
    }
});

$(document).ready(function () {
    var descriptionElement = $(".productPage__description-text");

    descriptionElement.removeClass("onload");

    var moreText = descriptionElement.data('more-text');
    var lessText = descriptionElement.data('less-text');

    $readMoreJS.init({
        target: '.productPage__description-text',
        numOfWords: 90,
        toggle: true,
        moreLink: moreText,
        lessLink: lessText,
        linkClass: 'show-text'
    });
});