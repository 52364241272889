require('babel-polyfill');

import axios from 'axios';

export default {
    async getCutSamples(productId, culture) {
        try {
            const request = await axios.get("/Umbraco/Api/Product/GetCutSamples", {
                params: {
                    productId: productId,
                    culture: culture
                }
            });

            return request;
        } catch (e) {
            return false;
        }
    },
    async getHanger(productId, culture) {
        try {
            const request = await axios.get("/Umbraco/Api/Product/GetHanger", {
                params: {
                    productId: productId,
                    culture: culture
                }
            });

            return request;
        } catch (e) {
            return false;
        }
    },
    async getSampleCard(productId, culture) {
        try {
            const request = await axios.get("/Umbraco/Api/Product/GetSampleCard", {
                params: {
                    productId: productId,
                    culture: culture
                }
            });

            return request;
        } catch (e) {
            return false;
        }
    },
    async getVariants(productId, culture) {
        try {
            const request = await axios.get("/Umbraco/Api/Product/GetVariants", {
                params: {
                    productId: productId,
                    culture: culture
                }
            });

            return request;
        } catch (e) {
            return false;
        }
    },
    async getProductsAndFilters(categoryId, activeFilters, culture) {
        try {
            const request = await axios.get("/Umbraco/Api/Product/GetProductsAndFilters", {
                params: {
                    categoryId: categoryId,
                    activeFilters: activeFilters,
                    culture: culture
                }
            });

            return request;
        } catch (e) {
            return false;
        }
    },
    async transformOldFilterParams(categoryId, oldFilterParams, culture) {
        try {
            const request = await axios.get("/Umbraco/Api/Product/TransformOldFilterParams", {
                params: {
                    categoryId: categoryId,
                    oldFilterParams: oldFilterParams,
                    culture: culture
                }
            });

            return request;
        } catch (e) {
            return false;
        }
    },
    async getFairProducts(categoryId, categoryCode, culture) {
        try {
            const request = await axios.get("/Umbraco/Api/Product/GetFairProducts", {
                params: {
                    categoryId: categoryId,
                    categoryCode: categoryCode,
                    culture: culture
                }
            });

            return request;
        } catch (e) {
            return false;
        }
    },
    async getSampleCards(categoryId, categoryCode, culture) {
        try {
            const request = await axios.get("/Umbraco/Api/Product/GetSampleCards", {
                params: {
                    categoryId: categoryId,
                    categoryCode: categoryCode,
                    culture: culture
                }
            });

            return request;
        } catch (e) {
            return false;
        }
    },
    async addCustomerToBasket(basketId, customer, culture) {
        try {
            const request = await axios.post("/Umbraco/Api/Checkout/AddCustomerToBasket", {
                basketId: basketId,
                customer: customer,
                culture: culture
            });
            return request;
        } catch (e) {
            return false;
        }
    },
    async createBasket(customer, customerAddedFromSalesForce, culture) {
        try {
            const request = await axios.post("/Umbraco/Api/Checkout/CreateBasket", {
                customer: customer,
                customeraddedfromsalesforce: customerAddedFromSalesForce,
                culture: culture
            });
            return request;
        } catch (e) {
            return false;
        }
    },
    async createOrUpdateBasketItem(basketId, product, customerAddedFromSalesForce, culture) {
        try {
            var orderLength = 0;
            if (product.hasOwnProperty('OrderLength')) orderLength = product.OrderLength;

            var cutmeasures = '';
            if (product.hasOwnProperty('CutMeasures')) cutmeasures = product.CutMeasures;

            var remark = '';
            if (product.hasOwnProperty('Remark')) remark = product.Remark;

            const request = await axios.post("/Umbraco/Api/Checkout/CreateOrUpdateBasketItem", {
                basketId: basketId,
                partNo: product.PartNo,
                quantity: product.Quantity,
                orderlength: orderLength,
                producttype: product.ProductType,
                isclimatescreen: product.IsClimateScreen,
                cutmeasures : cutmeasures,
                remark: remark,
                customeraddedfromsalesforce: customerAddedFromSalesForce,
                culture: culture,
            });
            return request;
        } catch (e) {
            return false;
        }
    },
    async updateDeliveryAddress(basketId, culture, address) {
        try {
            const request = await axios.post("/Umbraco/Api/Checkout/UpdateDeliveryAddress", {
                basketId: basketId,
                culture: culture,
                selectedDeliveryAddress: address
            });
            return request;
        } catch (e) {
            return false;
        }
    },
    async placeOrder(cart, chosenDeliveryAddressIndex, culture) {
        try {
            const request = await axios.post("/Umbraco/Api/Checkout/PlaceOrder", {
                cart: cart,
                chosenDeliveryAddressIndex: chosenDeliveryAddressIndex,
                culture: culture
            });
            return request;
        } catch (e) {
            return false;
        }
    },
    async clearBasket(basketId, culture) {
        try {
            const request = await axios.get("/Umbraco/Api/Checkout/ClearBasket", {
                params: {
                    basketId: basketId,
                    culture: culture
                }
            });
            return request;
        } catch (e) {
            return false;
        }
    },
    async login(user, culture) {
        try {
            const request = await axios.post('/Umbraco/Api/Account/Login', {
                emailaddress: user.emailaddress,
                password: user.password,
                culture: culture
            });
            return request;
        } catch (e) {
            return e;
        }
    },
    async resetPassword(emailaddress, contentid) {
        try {
            const request = await axios.post('/Umbraco/Ajax/MailMessage/ResetPassword', {
                emailaddress: emailaddress,
                contentid: contentid
            });
            return request;
        } catch (e) {
            return e;
        }
    },
    async registerNewCustomer(newCustomer, culture) {
        try {
            const request = await axios.post("/Umbraco/Api/Account/Apply", {
                culture: culture,
                customer: newCustomer
            });
            return request;
        } catch (e) {
            return false;
        }
    },
    async getCustomerFromSession() {
        try {
            return await axios.get("/Umbraco/Api/Account/GetCustomerFromSession");
        } catch (e) {
            return false;
        }
    },
    async hasValidUmbracoAccount(emailaddress) {
        try {
            const request = await axios.get("/Umbraco/Api/Account/HasValidUmbracoAccount", {
                params: {
                    emailaddress: emailaddress
                }
            });
            return request;
        } catch (e) {
            return false;
        }
    },
    async hasValidSalesForceAccount(emailaddress, culture) {
        try {
            const request = await axios.get("/Umbraco/Api/Account/HasValidSalesForceAccount", {
                params: {
                    emailaddress: emailaddress,
                    culture: culture
                }
            });
            return request;
        } catch (e) {
            return false;
        }
    },
    async getEmailFromAccount(uuid) {
        try {
            const request = await axios.get("/Umbraco/Api/Account/GetEmailFromAccount", {
                params: {
                    uuid: uuid
                }
            });
            return request;
        } catch (e) {
            return false;
        }
    },
    async getEmailFromContactId(cid) {
        try {
            const request = await axios.get("/Umbraco/Api/Account/GetEmailFromContactId", {
                params: {
                    cid: cid
                }
            });
            return request;
        } catch (e) {
            return false;
        }
    },
    async setPassword(uuid, isnew, emailAddress, password, passwordConfirm, culture) {
        try {
            const request = await axios.post("/Umbraco/Api/Account/SetPassword", {
                Uuid: uuid,
                IsNew: isnew,
                EmailAddress: emailAddress,
                Password: password,
                PasswordConfirm: passwordConfirm,
                Culture: culture
            });
            return request;
        } catch (e) {
            return false;
        }
    },
    async getProductPrice(productId, customerNumber, noOfDecimals) {
        try {
            const request = await axios.get("/Umbraco/Api/Product/GetProductPrice", {
                params: {
                    productId: productId,
                    customerNumber: customerNumber,
                    noOfDecimals: noOfDecimals || 2
                }
            });
            return request;
        } catch (e) {
            return false;
        }
    },
    async getOrderTotal(cart, deliveryAddressIndex, customerAddedFromSalesForce) {
        try {
            const request = await axios.post("/Umbraco/Api/Product/GetOrderTotal", {
                cart: cart,
                chosenDeliveryAddressIndex: deliveryAddressIndex,
                customerAddedFromSalesForce: customerAddedFromSalesForce
            });
            return request;
        } catch (e) {
            return false;
        }
    },
    async getCountries(culture) {
        try {
            const request = await axios.get("/Umbraco/Api/CultureInfo/GetCountries", {
                params: {
                    culture: culture
                }
            });
            return request;
        } catch (e) {
            return false;
        }
    },
    async getOrderHistory(pageNo, noOfDecimals) {
        try {
            return await axios.get("/Umbraco/Api/Order/GetOrderHistory?pageNo=" + pageNo + "&noOfDecimals=" + (noOfDecimals || 2));
        } catch (e) {
            return false;
        }
    },
    async getOrderConfirmationPdf(orderNumber) {
        try {
            return await axios.get("/Umbraco/Api/Order/GetOrderConfirmationPdf?orderNumber=" + orderNumber);
        } catch (e) {
            return false;
        }
    },
    async removeFromBasket(cart, partNo, culture) {
        try {
            const request = await axios.post("/Umbraco/Api/Checkout/RemoveFromBasket", {
                partno: partNo,
                cart: cart,
                culture: culture
            });
            return request;
        } catch (e) {
            return false;
        }
    },
    async searchProducts(searchTerm, returnFullResult, contentId, culture) {
        try {
            const request = await axios.get("/Umbraco/Api/ProductSearch/SearchProducts", {
                params: {
                    searchTerm: searchTerm,
                    returnFullResult: returnFullResult,
                    contentId: contentId,
                    culture: culture
                }
            });
            return request;
        } catch (e) {
            return false;
        }
    },
};
