export const CURRENT_CUSTOMER = 'CURRENT_CUSTOMER';
export const ADD_TO_CART = 'ADD_TO_CART';
export const CHANGE_QUANTITY = 'CHANGE_QUANTITY';
export const CHANGE_QUANTITY_AND_LENGTH = 'CHANGE_QUANTITY_AND_LENGTH';
export const CHANGE_QUANTITY_AND_CUTMEASURE = 'CHANGE_QUANTITY_AND_CUTMEASURE';
export const EMPTY_CART = 'EMPTY_CART';
export const ADD_BASKETID_TO_CART = 'ADD_BASKETID_TO_CART';
export const RESET_STATE = 'RESET_STATE';
export const RESET_STATE_KEEP_CUSTOMER = 'RESET_STATE_KEEP_CUSTOMER';
export const ADD_MARKING_TO_CART = 'ADD_MARKING_TO_CART';
export const CUSTOMER_EXISTS_IN_BASKET = 'CUSTOMER_EXISTS_IN_BASKET';
export const CUSTOMER_EXISTS_IN_CART = 'CUSTOMER_EXISTS_IN_CART';
export const UPDATE_CART_QUEUE = 'UPDATE_CART_QUEUE';
export const ADD_TO_CART_QUEUE = 'ADD_TO_CART_QUEUE';
export const REMOVE_FROM_CART_QUEUE = 'REMOVE_FROM_CART_QUEUE'; 
export const ADD_CUSTOMER_ORDERNUMBER_TO_CART = 'ADD_CUSTOMER_ORDERNUMBER_TO_CART';
export const ADD_NEW_DELIVERY_ADDRESS = 'ADD_NEW_DELIVERY_ADDRESS';
export const SAVE_CHOSEN_DELIVERY_ADDRESS = 'SAVE_CHOSEN_DELIVERY_ADDRESS';
export const CUSTOMER_ADDED_FROM_SALESFORCE = 'CUSTOMER_ADDED_FROM_SALESFORCE';
export const ADD_COPACKING_TO_CART = 'ADD_COPACKING_TO_CART';
export const ADD_PARTIAL_DELIVERY_ACCEPTED_TO_CART = 'ADD_PARTIAL_DELIVERY_ACCEPTED_TO_CART';
export const ADD_REQUESTED_DELIVERY_DATE_TO_CART = 'ADD_REQUESTED_DELIVERY_DATE_TO_CART';
export const RESET_CUSTOMER_IN_CART = 'RESET_CUSTOMER_IN_CART';
export const CURRENT_DELIVERY_ADDRESS_INDEX = 'CURRENT_DELIVERY_ADDRESS_INDEX';
export const REMOVE_PRODUCT_FROM_CART = 'REMOVE_PRODUCT_FROM_CART';
export const REMOVE_PRODUCT_FROM_CART_BY_PART_NO = 'REMOVE_PRODUCT_FROM_CART_BY_PART_NO';
export const ADD_NEW_DELIVERY_PHONE = 'ADD_NEW_DELIVERY_PHONE';