import CartActions from './actions.cart';
import CartMutations from './mutations.cart';


export const getDefaultCartState = () => {
    return {
        cart: {
            products: [],
            customer: {},
            goodsmarking: '',
            customerordernumber: '',
            copacking: false,
            partialdeliveryaccepted: false,
            requesteddeliverydate: '',
            basketId: 0
        },
        customerExistsInBasket: false,
        customerExistsInCart: false,
        customerAddedFromSalesForce: false,
        chosenDeliveryAddressIndex: -1,
        cartQueue: []
    }
} 

// initial state
const state = getDefaultCartState();

// getters
const getters = {
    cart: state => state.cart,
    customerExistsInBasket: state => state.customerExistsInBasket,
    customerExistsInCart: state => state.customerExistsInCart,
    customerAddedFromSalesForce: state => state.customerAddedFromSalesForce,
    chosenDeliveryAddressIndex: state => state.chosenDeliveryAddressIndex,
    cartQueue: state => state.cartQueue
};

export default {
    state: state,
    getters: getters,
    actions: CartActions,
    mutations: CartMutations
};