<template>
    <div class="col-xs-6 col-sm-6 col-md-3 margin-bottom">
        <img :src="thumbnailImageUrl" v-bind:class="{clickable : !hasPlaceHolderImage}" class="preview-thumbnail" :alt="item.ColorId" v-on="!hasPlaceHolderImage ? { click: showPreview } : null" />
        {{item.ColorId}}&nbsp;<span v-if="item.Discontinued">{{t['Products - Product Discontinued']}}</span><strong v-if="item.New">{{t['Products - New product variant']}}</strong>

        <product-price v-if="showPrice" :partno="parsedPartNo" :uom="item.Uom"></product-price>

        <div v-if="productExistsInCart">{{productInCart.Quantity}} {{t['Products - Pieces Abbreviation']}}</div>
        <div v-if="productExistsInCart" class="btn-group-xs">
            <button type="button" class="btn minus" v-on:click="quantityChange(item, 'decrement')"><i class="close fa fa-minus"></i></button>
            <button type="button" class="btn plus" v-on:click="quantityChange(item, 'increment')"><i class="close fa fa-plus"></i></button>
        </div>
        <div class="error-box alert alert-danger" v-if="hasDirtyCart">
            {{t['Products - Error Another Business Area']}}
        </div>
        <button class="btn btn-default btn c1-bg c1-text" v-if="productNotInCart && !item.Discontinued" @click="quantityChange(item, 'increment')">{{t['Products - Add to cart']}}</button>
        <i v-show="!hasPlaceHolderImage" class="fa fa-search-plus" @click="showPreview"></i>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ProductPrice from './ProductPrice';
import imagepreview from '../../imagepreview';
import cartvalidator from '../../cartvalidator';

export default {
    name: 'SampleItem',
    props: {
        item: {
            type: Object,
            required: true
        },
        showPrice: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            hasDirtyCart: false,            
            hasPlaceHolderImage: false
        };
    },
    components: {
        ProductPrice
    },
    computed: {
        ...mapGetters({
            t: 'translations',
            cart: 'cart'
		}),
        thumbnailImageUrl() {
            if (this.item.ThumbnailImage) {
                return this.item.ThumbnailImage;
            } else {
                this.hasPlaceHolderImage = true;
                return "/src/images/placeholder-182x140.png";
            }
        },
        largeImageUrl() {
            if (this.item.LargeImage) {
                return this.item.LargeImage;
            } else {
                return "/src/images/placeholder-847x424.png";
            }
        },
        productNotInCart(){
            return this.productInCart === undefined;
        },
        productExistsInCart(){
            return !this.productNotInCart;
        },
        productInCart(){
            return this.cart.products.find(p => p.Id === this.item.Id)
        },
        parsedPartNo() {
            return parseInt(this.item.PartNo);
        }
    },
    methods: {
        ...mapActions([
            'saveToCart',
            'changeQuantity'
        ]),
        getProductFromCart: function (product) {
            return this.cart.products.find(p => p.Id === product.Id);
        },
        quantityChange: function (product, direction) {

            if (cartvalidator.cartHasProductFromAnotherBusinessArea(this.cart.products, product)) {
                this.hasDirtyCart = true;
                return;
            } else {
                this.hasDirtyCart = false;
            }
            
            const foundProduct = this.productInCart;
            if (this.productInCart) {                    
                this.changeQuantity({ 'product': this.productInCart, 'direction': direction });
            } else {
                product.Quantity = 1;
                this.saveToCart(product).then(function(response){});
            }
        },
        showPreview: function(e){
            imagepreview.showLargeImage(e, this.item.ColorId, this.largeImageUrl);
        }
    }
};
</script>

<style lang="scss">
</style>