<template>
    <div v-bind:class="[isCutMeasure ? 'col-md-4': 'col-md-3' ]" class="col-xs-6 col-sm-6 margin-bottom">
        <cut-measure-variant-item v-if="isCutMeasure" :item="item" />
        <normal-variant-item v-else :item="item" />
    </div>
</template>

<script>
import CutMeasureVariantItem from './CutMeasureVariantItem';
import NormalVariantItem from './NormalVariantItem'

export default {
    name: 'VariantItemSelector',
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data: function() {
        return {
        };
    },
    computed: {
        isCutMeasure() {
            return this.item.CartRule == 'Cut measure';
        }
    },
    components: {
        NormalVariantItem,
        CutMeasureVariantItem
    }
};
</script> 