<script>
import { mapGetters, mapActions } from 'vuex';

export default {
	name: 'VariantCallToAction',
    props: {
        thumbnailimage: {
            type: String
        },
        colorid: {
            type: String,
            required: true
        },
        variantname: {
            type: String,
            required: true
        },
        usertype: {
            type: String,
            required: true
        }
    },
    data: function() {
        return {
        };
    },
    computed: {
        ...mapGetters({
            cart: 'cart'
        }),
        thumbnailImageUrl() {
            if (this.thumbnailimage) {
                return this.thumbnailimage;
            } else {
                return "/src/images/placeholder-variant.png";
            }
        },
        tabToOpen() {
            if (this.usertype == "payingcustomer" || (this.usertype == "salesrep" && this.cart.customer.companyName)) {
                return 0;
            }

            return 1;
        }
    },
    methods: {
        ...mapActions([
            'selectTab'
        ]),
		openTab(e) {
            var index = Number(e.target.dataset.index);            
            this.selectTab(index);
            document.body.classList.add("modal-open");
		}
    }
};
</script>

<template>
    <div class="variant">
        <img class="lazyload" v-bind:data-src="thumbnailImageUrl" @click="openTab" :data-index="tabToOpen" v-bind:alt="variantname" /><br />
    </div>
</template>

<style lang="scss">
</style>
