
<template>
    <div>
        <vue-modaltor :visible="sessionExpiredModal" @hide="hideModal" :defaultWidth="'400px'">
            <h6>{{t['Checkout - Session expired']}}</h6>
            <p>{{t['Checkout - Please return to Sales Force']}}</p>
        </vue-modaltor>
        <vue-modaltor :visible="errorModal" @hide="hideModal" :defaultWidth="'400px'">
            <h6>An error occurred</h6>
            <p>Please report this error with the time stamp ({{currentdatetime}}).</p>
            <p>Close your browser and start again from Sales force.</p>
        </vue-modaltor>

        <div v-if="logText" id="logtext">{{logText}}</div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import apiService from "../services/apiservice";
import VueModalTor from "vue-modaltor";
const queryString = require('query-string');
import moment from 'moment';
export default {
    name: 'SessionHandler',
    props: {
        isloggedin: {
            type: Boolean,
            required: true
        },
    },
    data: function() {
        return {
            sessionExpiredModal: false,
            errorModal: false,
            logText: ''
        };
    },
    computed: {
        ...mapGetters({
            customerAddedFromSalesForce: 'customerAddedFromSalesForce',
            t: 'translations'
        }),
        currentdatetime() {
            return moment().format();
        }
    },
    methods: {
        ...mapActions([
            'addCustomerToCart',
            'resetCartState',
            'closeModal',
            'resetCustomerInCart'
        ]),
        hideModal() {
            this.sessionExpiredModal = false;
            this.errorModal = false;
            this.closeModal();
            document.body.classList.remove("modal-open");
        },
    },
    mounted() {
        var query = queryString.parse(location.search);
        if (query.sf === "true") {
            this.resetCartState();
            history.pushState(null, "", location.href.split("?")[0]);
            var self = this;
            apiService.getCustomerFromSession().then(function (result) {
                if (result !== false) {
                    var payload = {
                        customer: result.data,
                        customerAddedFromSalesForce: true
                    }
                    self.addCustomerToCart(payload).then((result) => {
                        $("#AddedCustomerToCart").animate({ display: "toggle" }, 1500);
                    });
                } else {
                    self.errorModal = true;
                }
            });
        }

        if (query.debug === "true") {
            try {
                for (var i = 0; i < localStorage.length; i++)   {
                    if (localStorage.key(i) === "vuex") {
                        var storageValue = localStorage.getItem(localStorage.key(i));
                        if (storageValue) {
                            var j = JSON.parse(storageValue);
                            this.logText = j.cartModule;
                        }
                    }
                }                
            } catch (error) {
                this.logText = error;
            }
        }

        if (!this.isloggedin && this.customerAddedFromSalesForce) {
            document.body.classList.add("modal-open");
            this.sessionExpiredModal = true;
            this.resetCartState();
        }
    }
};
</script>

<style lang="scss" scoped>
	#logtext {
        position: fixed;
        top: 0;
        background: palegoldenrod;
        z-index: 995;
        padding: 20px;
    }
</style>