<script>
import { mapActions, mapGetters } from 'vuex';
import ProductItem from './ProductItem';
import apiservice from '../services/apiservice';
const queryString = require('query-string');

export default {
    name: 'ProductList',
    props: {
        categoryid: {
            type: String
        },
        productsjson: {
            type: String
        },
        filtersjson: {
            type: String
        },
        productbaseurl: {
            type: String,
            required: true
        },
        csproduct: {
            type: Boolean
        },
        hiddenfiltergroups: {
            type: String
        },
        predefinedfilters: {
            type: String
        }
    },
    data: function() {
        return {
            openFilters: [],
            activeFilters: [],
            products: [],
            filters: []
        };
    },
    components: {
        ProductItem
    },
    computed: {
        ...mapGetters({
            t: 'translations',
            culture: 'culture', 
        }),        
        filterGroupsToHide(){
            if (this.hiddenfiltergroups){
                return this.hiddenfiltergroups.split(',').map(function(item) {
                    return item.trim();
                });
            }
        }
    },    
    methods: {
        isActiveFilterGroup(filterId) {
            return this.openFilters.indexOf(filterId) > -1;
        },
        filterGroupIsNotHidden(filterId) {
            if (this.filterGroupsToHide) {
                return this.filterGroupsToHide.indexOf(filterId) == -1;
            }

            return true;
        },
        filterGroupToggle(filterId) {
            var visibleIndex = this.openFilters.indexOf(filterId);
            if (visibleIndex > -1) {
                this.openFilters.splice(visibleIndex, 1);
            } else {
                this.openFilters.push(filterId);
            }
        },
        filterOptionToggle: function(e){
            var filterValue = e.target.value;
            var filterIndex = this.activeFilters.indexOf(filterValue);
            if (e.target.checked) {
                if (filterIndex == -1) {
                    this.activeFilters.push(filterValue);
                }
            } else {
                if (filterIndex > -1) {
                    this.activeFilters.splice(filterIndex, 1);                    
                }
            }

            this.updateLocationHash(this.activeFilters);
        },
        isActiveFilterOption(code) {
            return this.activeFilters.indexOf(code) > -1;
        },
        updateLocationHash(activeFilters) {
            location.hash = queryString.stringify({
                filter: JSON.stringify(activeFilters)
            });
        },
        loadProductsAndFilters() {
            apiservice.getProductsAndFilters(this.categoryid, this.activeFilters, this.culture).then((response) => {
                this.products = response.data.Products;
                this.filters = response.data.Filters;

                this.openFilters = [];
                for (var i = 0; i < this.filters.length; i++) {
                    var filter = this.filters[i];
                    var filterHasActiveItems = false;
                    for (var y = 0; y < filter.Items.length; y++) {
                        var filterItem = filter.Items[y];
                        filterHasActiveItems = this.activeFilters.indexOf(filterItem.Code) > -1;
                        if (filterHasActiveItems) {
                            break;
                        }
                    }
                    if (filterHasActiveItems) {
                        this.openFilters.push(filter.Id);
                    }
                }
            });
            
            this.setCategoryClass();
        },
        setCategoryClass() {
            if (!this.csproduct) {
                return;
            }

            var categories = [];
            for(var i = 0; i < this.products.length; i++) {
                var item = this.products[i];
                var className = item.Name.substring(0, item.Name.indexOf(" "));

                if (categories.indexOf(className) == -1) {
                    categories.push(className);
                }
            }

            if (categories.length == 1) {
                var container = document.getElementsByClassName("productCategoryPage")[0];
                container.classList.add(categories[0]); 
            }
        },
        loadFromPredefinedFilters(parsedFilters, updateHash) {
            var oldFilterFormatParams = [];
            for (var i = 0; i < parsedFilters.length; i++) {
                if (parsedFilters[i].indexOf(':') > -1) {
                    oldFilterFormatParams.push(parsedFilters[i]);
                }
            }

            if (oldFilterFormatParams.length > 0) {
                apiservice.transformOldFilterParams(this.categoryid, oldFilterFormatParams, this.culture).then((response) => {
                    this.activeFilters = response.data;
                    if (updateHash) {
                        this.updateLocationHash(this.activeFilters);
                    } else {
                        this.loadProductsAndFilters();
                    }
                });
            } else {
                this.activeFilters = parsedFilters;
                this.loadProductsAndFilters();
            }
        },
        toggleMobileFilter() {
            var element = $(".product-filter");
            var clearButton = $(".product-filter__clear");

            if (element.hasClass("hidden-xs hidden-sm")) {
                element.removeClass("hidden-xs hidden-sm");
                clearButton.removeClass("hidden-xs hidden-sm");
                $(".btn-filter").html('<i class="fa fa-filter"></i>' + this.t['Products - Hide filter']);
                
            } else {
                element.toggleClass("hidden-xs hidden-sm");
                clearButton.toggleClass("hidden-xs hidden-sm");
                $(".btn-filter").html('<i class="fa fa-filter"></i>' + this.t['Products - Show filter']);
            }
        },
        clearFilters() {
            this.activeFilters = [];
            this.openFilters = [];
            this.updateLocationHash(this.activeFilters);
        },
        init() {
            var self = this;
            var parsed = queryString.parse(location.hash);
            if (parsed.filter) { // Filter applied as location hash
                var parsedFilters = JSON.parse(parsed.filter);
                this.loadFromPredefinedFilters(parsedFilters, true);
            } else if (this.predefinedfilters) { // Filter is pre defined in cms
                parsed = queryString.parse(this.predefinedfilters);
                if (parsed.filter) {
                    var parsedFilters = JSON.parse(parsed.filter);
                    this.loadFromPredefinedFilters(parsedFilters, false);
                }
            } else { // Filter and products are pre-loaded from server
                this.products = JSON.parse(this.productsjson);
                this.filters = JSON.parse(this.filtersjson);
                this.setCategoryClass();
            }
        },
    },
    created() {
        var self = this;
        window.addEventListener("hashchange", function(event) {
            self.init(); 
        }, false);

        this.init(); 
    }
};
</script>
<template>
    <div>
        <div class="col-md-3 product-filter__container">
            <button type="button" class="btn btn-filter c1-bg c1-text hidden-md hidden-lg" @click="toggleMobileFilter"><i class="fa fa-filter"></i> {{t['Products - Show filter']}}</button>
            
            <ul class="product-filter hidden-xs hidden-sm">
                <li class="filtergroup" v-show="filterGroupIsNotHidden(filterGroup.Id)" :class="{ active: isActiveFilterGroup(filterGroup.Id) }" v-for="(filterGroup, index) in filters" :key="index" @click="filterGroupToggle(filterGroup.Id)">
                    <div>
                        <span>{{filterGroup.Name}}</span> <i :class="isActiveFilterGroup(filterGroup.Id) ? 'fa-angle-up' :'fa-angle-down'" class="fa after"></i>
                    </div>
                    <ul>
                        <li v-for="(filterOption, index) in filterGroup.Items" :key="index" :class="[isActiveFilterOption(filterOption.Code) ? 'checked' :'']">
                            <label class="checkbox-container">
                                <input class="checkbox" type="checkbox" :checked="isActiveFilterOption(filterOption.Code)" :value="filterOption.Code" @click="filterOptionToggle" /> <span class="check-name">{{filterOption.Name}} <!--({{filterOption.NumberOfResults}})--></span>
                                <span class="checkmark"></span>
                            </label>
                        </li>
                    </ul>
                </li>
            </ul>

            <div v-if="activeFilters.length > 0" class="product-filter__clear hidden-xs hidden-sm">
                <button @click="clearFilters">{{t['Products - Clear filter']}}</button>
            </div>
        </div>

        <div class="col-md-9 product-list">
            <product-item v-for="(item, index) in products" :productbaseurl="productbaseurl" :item="item" :index="index" :key="index" :csproduct="csproduct" />
        </div>
    </div>
</template>

<style lang="scss">
    .product-filter {
        margin-left: 0;
        list-style-type: none;
        
        .filtergroup {
            cursor: pointer;
            border-bottom: 1px solid #cccccc;
            padding: 10px 0;

            i {
                float:right;
            }

            ul {
                display: none;
            }

            &.active {
                ul {
                    list-style-type: none;
                    display: block;
                    cursor: auto;
                    background: #eeeeee;
                    margin-left: 0px;
                    padding: 10px 0px 10px 20px;
                }
            }
        }
    }
</style>