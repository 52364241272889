<script>
import { mapGetters } from 'vuex';
import apiService from "../services/apiservice";
import SampleItem from "./SampleItem";

export default {
    name: 'CutSampleList',
    props: {
        productid: {
            type: Number,
            required: true
        },        
        productname: {
            type: String,
            required: true
        },
        addtocartpopuparticletext: {
            type: String,
        },
    },
    data: function() {
        return {
            products: [],
            filterisloading : true
        };
    },
    components: {
        SampleItem
    },
    computed: {
        ...mapGetters({
            t: 'translations',
            culture: 'culture'
        }),
        headline(){
            return this.t['Products - Cut Sample Header'].replace('{0}', this.productname);
        },
        textExists() {
            return this.t['Products - AddToCart Popup text'] != '';
        },
        articleTextExists() {
            return this.addtocartpopuparticletext != '';
        },
        productRows(){
            var rows = [];
            var rowProducts = [];
            for (let i = 0; i < this.products.length; i++) {
                const product = this.products[i];
                
                if (i > 0 && i % 4 == 0) {
                    rows.push(rowProducts);
                    rowProducts = [];
                    rowProducts.push(product);
                } else {
                    rowProducts.push(product);
                }

                if (i == (this.products.length -1)) {
                    rows.push(rowProducts)
                }
            }

            return rows;
        }
    },
    created() {
        var self = this;
        apiService.getCutSamples(this.productid, this.culture).then(function(response) {
            if (response !== false) {
                self.filterisloading = false;
                self.products = response.data;
            }
        });
    }
};
</script>
<template>
    <div>
        <div class="col-md-12">
            <h2>{{headline}}</h2>
        </div>

        <div class="col-md-12 modal-popup-text" v-if="textExists">
            <span>{{t['Products - AddToCart Popup text']}}</span>
        </div>
        <div class="col-md-12 modal-popup-text" v-if="articleTextExists">
            {{this.addtocartpopuparticletext}}
        </div>

        <div class="col-md-12">
            <div class="col-md-12 panel panel-default">
                <span v-html="t['Products - Cut Sample Help Text']"></span>
            </div>
            <span v-if="filterisloading">
                <i class="fa fa-cog fa-spin" style="font-size:24px"></i>
                {{t['Products - Loading products']}}
            </span>

            <div v-if="productRows.length > 0">
                <div v-for="(row, rowindex) in productRows" :key="'row' + rowindex" class="row product-row">
                    <sample-item v-for="(item, index) in row" :key="'product' + index" :item="item" :showPrice="false" />
                </div>
            </div>            
            <div v-else-if="!filterisloading">
                {{t['Products - Non Available']}}
            </div>
        </div>
    </div>
</template>
