<template>
    <div class="form">
        <div class="row">
            <div class="col-md-6">                
                <div class="form-group">
                    <label for="inputEmail">{{t['Common - Email']}}</label>
                    <input type="email" class="form-control" v-model="newcustomer.email" v-validate="'required|email'" disabled="disabled" id="inputEmail" name="email">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">                
                <div class="form-group dropdown">
                    <label for="selectBusinessarea">{{t['Register - Customer Business Area']}}</label>
                    <select class="form-control" v-model="newcustomer.selectedbusinessarea" id="selectBusinessarea">
                        <option value="" disabled selected hidden>{{t['Register - Select Option']}}</option>
                        <option v-for="businessarea in businessareas" v-bind:value="businessarea.value" v-bind:key="businessarea.value">
                            {{ businessarea.text }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row" v-if="newcustomer.selectedbusinessarea">
            <div class="col-md-6">                
                <div class="form-group dropdown">
                    <label for="selectedcustomerrole">{{t['Register - Customer Role']}}</label>
                    <select class="form-control" v-model="newcustomer.selectedcustomerrole" id="selectCustomer">
                        <option value="" disabled selected hidden>{{t['Register - Select Option']}}</option>
                        <option v-for="customerrole in customerroles[newcustomer.selectedbusinessarea]" v-bind:value="customerrole.value" v-bind:key="customerrole.value">
                            {{ customerrole.text }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div v-if="newcustomer.selectedcustomerrole">
            <div class="row">
                <div class="col-md-12">
                    <hr />
                </div>
            </div>
            <!--
        <div class="row">
            <div class="col-md-12">
                <div class="checkbox">
                    <label class="checkbox-container" for="checkboxCreditCheck">
                        <input type="checkbox" class="checkbox" id="checkboxCreditCheck" ref="creditcheck" name="creditcheck" value="true" v-model="newcustomer.agreetocreditcheck" />
                        <span class="checkmark"></span>{{t['Register - Customer Credit Check']}}
                    </label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <hr />
            </div>
     </div>
    -->
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="inputCompanyName">{{t['Common - Company Name']}}</label>
                        <input type="text" maxlength="100" class="form-control" id="inputCompanyName" name="companyname" v-validate="'required'" v-bind:data-vv-as="t['Common - Company Name']" v-model="newcustomer.companyname" />
                        <span class="alert-danger">{{ vErrors.first('companyname') }}</span>
                    </div>
                </div>
                <!--
            <div class="col-md-6">
                <div class="form-group">
                    <label for="inputVATNumber">{{t['Common - VAT Number']}}</label>
                    <input type="text" class="form-control" id="inputVATNumber" name="vatnumber" v-validate="'required_if:creditcheck,true'" v-bind:data-vv-as="t['Common - VAT Number']" v-model="newcustomer.companyvatnumber" />
                    <span class="alert-danger">{{ vErrors.first('vatnumber') }}</span>
                </div>
            </div>
        -->
            </div>
            <div class="row">
                <div class="col-md-12">
                    <hr />
                </div>
            </div>
            <div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="inputAddress1">{{t['Common - Address1']}}</label>
                            <input type="text" class="form-control" id="inputAddress1" name="address1" v-validate="'required'" v-bind:data-vv-as="t['Common - Address1']" v-model="newcustomer.postaladdress1" />
                            <span class="alert-danger">{{ vErrors.first('address1') }}</span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="inputAddress2">{{t['Common - Address2']}}</label>
                            <input type="text" class="form-control" id="inputAddress2" name="address2" v-model="newcustomer.postaladdress2" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="inputZipcode">{{t['Common - Zipcode']}}</label>
                            <input type="text" class="form-control" id="inputZipcode" name="zipcode" maxlength="9" v-validate="'required'" v-bind:data-vv-as="t['Common - Zipcode']" v-model="newcustomer.postalzipcode" />
                            <span class="alert-danger">{{ vErrors.first('zipcode') }}</span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="inputCity">{{t['Common - City']}}</label>
                            <input type="text" class="form-control" id="inputCity" name="city" maxlength="30" v-validate="'required'" v-bind:data-vv-as="t['Common - City']" v-model="newcustomer.postalcity" />
                            <span class="alert-danger">{{ vErrors.first('city') }}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group dropdown">
                            <label for="selectCountry">{{t['Common - Country']}}</label>
                            <select class="form-control" v-model="newcustomer.postalcountry" id="selectCountry" name="country" v-validate="'required'" v-bind:data-vv-as="t['Common - Country']">
                                <option value="" disabled selected hidden>{{t['Register - Select Option']}}</option>
                                <option v-for="country in countries" v-bind:value="country.value" v-bind:key="country.value">
                                    {{ country.text }}
                                </option>
                            </select>
                            <span class="alert-danger">{{ vErrors.first('country') }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!--
                <div class="row">
                    <div class="col-md-12">
                        <div class="checkbox">
                            <label class="checkbox-container" for="checkboxDeliveryAddress">
                                <input type="checkbox" class="checkbox" ref="usedeliveryaddress" v-model="newcustomer.usedifferentdeliveryaddress" v-validate v-bind:data-vv-as="t['Register - Customer Use Delivery Address']" id="checkboxDeliveryAddress" name="checkboxdeliveryaddress" value="true" />
                                <span class="checkmark"></span>{{t['Register - Customer Use Delivery Address']}}
                            </label>
                        </div>
                    </div>
                </div>
            -->
            <div v-if="newcustomer.usedifferentdeliveryaddress">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="inputDeliveryAddress1">{{t['Common - Address1']}}</label>
                            <input type="text" class="form-control" id="inputDeliveryAddress1" name="deliveryaddress1" v-validate="'required_if:usedeliveryaddress,true'" v-bind:data-vv-as="t['Common - Address1']" v-model="newcustomer.deliveryaddress1" />
                            <span class="alert-danger">{{ vErrors.first('deliveryaddress1') }}</span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="inputDeliveryAddress2">{{t['Common - Address2']}}</label>
                            <input type="text" class="form-control" id="inputDeliveryAddress2" name="deliveryaddress2" v-model="newcustomer.deliveryaddress2" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="inputDeliveryZipcode">{{t['Common - Zipcode']}}</label>
                            <input type="text" class="form-control" id="inputDeliveryZipcode" name="deliveryzipcode"  maxlength="9" v-validate="'required_if:usedeliveryaddress,true'" v-bind:data-vv-as="t['Common - Zipcode']" v-model="newcustomer.deliveryzipcode" />
                            <span class="alert-danger">{{ vErrors.first('deliveryzipcode') }}</span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="inputDeliveryCity">{{t['Common - City']}}</label>
                            <input type="text" class="form-control" id="inputDeliveryCity" name="deliverycity" maxlength="30" v-validate="'required_if:usedeliveryaddress,true'" v-bind:data-vv-as="t['Common - City']" v-model="newcustomer.deliverycity" />
                            <span class="alert-danger">{{ vErrors.first('deliverycity') }}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group dropdown">
                            <label for="selectDeliveryCountry">{{t['Common - Country']}}</label>
                            <select class="form-control" v-model="newcustomer.deliverycountry" id="selectDeliveryCountry" name="deliverycountry" v-validate="'required_if:usedeliveryaddress,true'" v-bind:data-vv-as="t['Common - Country']">
                                <option value="" disabled selected hidden>{{t['Register - Select Option']}}</option>
                                <option v-for="country in countries" v-bind:value="country.value" v-bind:key="country.value">
                                    {{ country.text }}
                                </option>
                            </select>
                            <span class="alert-danger">{{ vErrors.first('deliverycountry') }}</span>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-md-12">
                    <hr />
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="inputContactPersonFirstName">{{t['Register - Contact Person First Name']}}</label>
                        <input type="text" class="form-control" id="inputContactPersonFirstName" name="contactpersonfirstname" v-validate="'required'" v-bind:data-vv-as="t['Register - Contact Person First Name']" v-model="newcustomer.contactpersonfirstname" />
                        <span class="alert-danger">{{ vErrors.first('contactpersonfirstname') }}</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="inputContactPersonLastName">{{t['Register - Contact Person Last Name']}}</label>
                        <input type="text" class="form-control" id="inputContactPersonLastName" name="contactpersonlastname" v-validate="'required'" v-bind:data-vv-as="t['Register - Contact Person Last Name']" v-model="newcustomer.contactpersonlastname" />
                        <span class="alert-danger">{{ vErrors.first('contactpersonlastname') }}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="inputPhone">{{t['Common - Phone']}}</label>
                        <input type="text" maxlength="20" class="form-control" id="inputPhone" name="phone" v-validate="'required'" v-bind:data-vv-as="t['Common - Phone']" v-model="newcustomer.phone" />
                        <span class="alert-danger">{{ vErrors.first('phone') }}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <hr />
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="checkbox">
                        <label class="checkbox-container" for="checkboxTerms">
                            <input type="checkbox" class="checkbox" id="checkboxTerms" name="terms" value="true" v-validate="'required'" v-bind:data-vv-as="t['Register - Terms Validation']" v-model="newcustomer.agreetoterms" />
                            <span class="checkmark"></span>{{t['Register - Terms Pretext']}} <a v-bind:href="terms" target="_blank">{{t['Register - Terms Linktext']}}</a>{{t['Register - Terms Posttext']}}
                        </label>
                        <span class="alert-danger">{{ vErrors.first('terms') }}</span>
                    </div>
                    <div class="checkbox">
                        <label class="checkbox-container" for="checkboxGdpr">
                            <input type="checkbox" class="checkbox" id="checkboxGdpr" name="gdpr" value="true" v-validate="'required'" v-bind:data-vv-as="t['Register - GDPR Validation']" v-model="newcustomer.agreetogdpr" />
                            <span class="checkmark"></span>{{t['Register - GDPR Pretext']}} <a v-bind:href="gdpr" target="_blank">{{t['Register - GDPR Linktext']}}</a>{{t['Register - GDPR Posttext']}}
                        </label>
                        <span class="alert-danger">{{ vErrors.first('gdpr') }}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <hr />
                </div>
            </div>
            <div class="row" v-if="vErrors.all().length">
                <div class="col-md-12">
                    <div class="alert alert-danger">
                        <ul>
                            <li v-for="(error, index) in vErrors.all()" :key="index">{{ error }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 text-center">
                    <button type="button" class="btn c1-bg c1-text" v-bind:disabled="applicationSubmitted" v-on:click="apply()">{{t['Register - Apply']}}</button>
                </div>
            </div>
        </div>

        <vue-modaltor :visible="applicationSubmitted" @hide="hideModal" :defaultWidth="'400px'" :showCloseButton="false">
            <h6>{{t['Register - Thank You Headline']}}</h6>
            <p>{{t['Register - Thank You Text']}}</p>
        </vue-modaltor>

        <vue-modaltor :visible="applicationError" @hide="hideModal" :defaultWidth="'400px'">
            <h6>{{t['Common - Error Occurred']}}</h6>
            <p>{{t['Register - Error Text']}}</p>
        </vue-modaltor>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import apiService from '../services/apiservice';
import VueModalTor from "vue-modaltor";
export default {
    name: 'RegisterForm',
    props: {
        email: {
            type: String,
            required: true
        },
        terms: {
            type: String,
            required: true
        },
        gdpr: {
            type: String,
            required: true
        },
        loginlink: {
            type: String,
            required: true
        }
    },
    data: function () {
        return {
            applicationSubmitted: false,
            applicationError: false,
            countries: [],
            newcustomer: {
                email: this.email,
                selectedbusinessarea: '',
                selectedcustomerrole: '',
                companyname: '',
                companyvatnumber: '',
                postaladdress1: '',
                postaladdress2: '',
                postalzipcode: '',
                postalcity:'',
                postalcountry: '',
                usedifferentdeliveryaddress: false,
                deliveryaddress1: '',
                deliveryaddress2: '',
                deliveryzipcode: '',
                deliverycity: '',
                deliverycountry: '',
                contactpersonfirstname: '',
                contactpersonlastname: '',
                phone: '',
                agreetocreditcheck: false,
                agreetoterms: false,
                agreetogdpr: false
            }            
        }
    },
    computed: {
        ...mapGetters({
            t: 'translations',
            culture: 'culture'
        }),
        businessareas() {
            return [
                { text: this.t['Common - Climate Screens'], value: 'CS' },
                { text: this.t['Common - Interior Textiles'], value: 'IT' }
            ];
        },
        customerroles() {
            return {
                'IT' : [
                        { text: this.t['Register - IT1'], value: this.t['Register - IT1'] },
                        { text: this.t['Register - IT2'], value: this.t['Register - IT2'] },
                        { text: this.t['Register - IT3'], value: this.t['Register - IT3'] },
                        { text: this.t['Register - IT4'], value: this.t['Register - IT4'] },
                        { text: this.t['Register - IT5'], value: this.t['Register - IT5'] },
                        { text: this.t['Register - IT6'], value: this.t['Register - IT6'] },
                        { text: this.t['Register - IT7'], value: this.t['Register - IT7'] },
                        { text: this.t['Register - IT8'], value: this.t['Register - IT8'] },
                        { text: this.t['Register - IT9'], value: this.t['Register - IT9'] },
                        { text: this.t['Register - IT10'], value: this.t['Register - IT10'] },
                        { text: this.t['Register - IT11'], value: this.t['Register - IT11'] },
                        { text: this.t['Register - IT12'], value: this.t['Register - IT12'] }
                    ],
                'CS' : [
                        { text: this.t['Register - CS1'], value: this.t['Register - CS1'] },
                        { text: this.t['Register - CS2'], value: this.t['Register - CS2'] },
                        { text: this.t['Register - CS3'], value: this.t['Register - CS3'] },
                        { text: this.t['Register - CS4'], value: this.t['Register - CS4'] },
                        { text: this.t['Register - CS5'], value: this.t['Register - CS5'] },
                        { text: this.t['Register - CS6'], value: this.t['Register - CS6'] },
                        { text: this.t['Register - CS7'], value: this.t['Register - CS7'] },
                        { text: this.t['Register - CS8'], value: this.t['Register - CS8'] },
                        { text: this.t['Register - CS9'], value: this.t['Register - CS9'] },
                        { text: this.t['Register - CS10'], value: this.t['Register - CS10'] },
                        { text: this.t['Register - CS11'], value: this.t['Register - CS11'] },
                        { text: this.t['Register - CS12'], value: this.t['Register - CS12'] }
                    ]
            };
        }
    },
    methods: {
        ...mapActions([
            'closeModal'
        ]),
        hideModal() {
            if (this.applicationSubmitted) {
                return;
            }

            this.applicationSubmitted = false;
            this.applicationError = false;
            this.closeModal();
            document.body.classList.remove("modal-open");
        },
        apply() {
            var self = this;

            self.$validator.validateAll().then((result) => {
                if (result) {
                    $(".loading-spinner").show();

                    apiService.registerNewCustomer(self.newcustomer, self.culture).then(function (result) {
                        if (result && result.data) {
                            $(".loading-spinner").hide();
                            if (result.data.Success) {
                                self.applicationSubmitted = true;
                            } else {
                                self.applicationError = true;
                            }
                        }
                    });
                }
            });
        }
    },
    mounted() {
        var self = this;
        apiService.getCountries(this.culture).then((result) => {
            if (result) {         
                for (var key in result.data) {
                    self.countries.push({
                        text: key,
                        value: result.data[key]
                    });
                }
            }
        });
    }
};
</script>
<style lang="scss">
</style>