<script>
import { mapGetters, mapActions } from 'vuex';
import cartvalidator from '../../cartvalidator';

export default {
    name: 'ClimateScreenCallToAction',
    props: ['product','purchaselimit'],
    data: function() {
        return {
            hasDirtyCart: false
        };
    },
    computed: {
        ...mapGetters({
            t: 'translations',
            cart: 'cart'
        }),        
        productNotInCart(){
            return this.productInCart === undefined;
        },
        productExistsInCart(){
            return !this.productNotInCart;
        },
        productInCart(){
            return this.cart.products.find(p => p.Id === this.product.Id)
        },
        quantity() {
            const foundProduct = this.productInCart;
            if (foundProduct) {
                return foundProduct.Quantity;
            } else {
                return "";
            }
        },
        disableAddToCart(product) {
            const foundProduct = this.productInCart;
            if (foundProduct) {
                return foundProduct.Quantity >= this.purchaselimit;
            } else {
                return false;
            }
        }
    },
    methods: {
        ...mapActions([
            'changeQuantity',
            'saveToCart'
        ]),
        quantityChange: function (product, direction) {
            if (cartvalidator.cartHasProductFromAnotherBusinessArea(this.cart.products, product)) {
                this.hasDirtyCart = true;
                return;
            } else {
                this.hasDirtyCart = false;
            }

            const foundProduct = this.productInCart;
            if (this.productInCart) {                    
                this.changeQuantity({ 'product': this.productInCart, 'direction': direction, 'purchaselimit': this.purchaselimit });
            } else {
                product.Quantity = 1;
                this.saveToCart(product).then(function(response){});
            }
        }
    }
};
</script>
<template>
    <div>
        <div class="btn-group-xs" v-if="productExistsInCart">
            <button type="button" class="btn minus" v-bind:class="{ zero: productNotInCart }" v-on:click="quantityChange(product, 'decrement')"><i class="close fa fa-minus"></i></button>
            {{quantity}}
            <button type="button" class="btn plus" v-bind:class="{ disabled: disableAddToCart }"  v-on:click="quantityChange(product, 'increment')"><i class="close fa fa-plus"></i></button>
        </div>        
        <div class="error-box alert alert-danger" v-if="hasDirtyCart">
            {{t['Products - Error Another Business Area']}}
        </div>
        <button class="btn btn-default btn c1-bg c1-text" v-if="productNotInCart" @click="quantityChange(product, 'increment')">{{t['Products - Select sample']}}</button>
    </div>
</template>
