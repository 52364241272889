require('babel-polyfill');

export default {
    showLargeImage(e, colorId, largeImageUrl){
        closeAllPreviews();
        
        var rowElement = findRowElement(event.target);
        
        var rowDiv = document.createElement("DIV");
        rowDiv.className = 'row preview';

        var columnDiv = document.createElement("DIV");
        columnDiv.className = 'col-md-12';   
        
        var colorSpan = document.createElement("SPAN");
        colorSpan.className = 'color';
        colorSpan.innerHTML = colorId;

        var closeIcon = document.createElement("I");
        closeIcon.className = 'close-preview fa fa-search-minus';  
        closeIcon.addEventListener("click", function(e) {
            closeAllPreviews();
        }, false);      

        var image = document.createElement("IMG");
        image.src = largeImageUrl;
        image.addEventListener("click", function(e) {
            closeAllPreviews();
        }, false);

        columnDiv.appendChild(colorSpan);
        columnDiv.appendChild(closeIcon);
        columnDiv.appendChild(image);
        rowDiv.appendChild(columnDiv);

        rowElement.parentNode.insertBefore(rowDiv, rowElement);

        function findRowElement(el) {
            while (el.parentNode) {
                el = el.parentNode;
                if (el.className === "row product-row")
                    return el;
            }
            return null;
        }

        function closeAllPreviews() {
            var previews = document.getElementsByClassName('preview');
            while (previews[0]) {
                previews[0].parentNode.removeChild(previews[0]);
            }
        }
    }
}